import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Input,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import { URLS } from "../../Url"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"

function ViewUser() {
  const [form, setform] = useState([])

  const [states, setstates] = useState([])

  const [District, setDistrict] = useState([])

  const [City, setCity] = useState([])

  const [news, setnews] = useState([])

  const [ADS, setADS] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  useEffect(() => {
    GetuserDetails()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Agentid = sessionStorage.getItem("Viewuserid")

  const GetuserDetails = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("_id", Agentid)
    axios
      .post(URLS.GetOneDetails, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.CustomerResult)
        setnews(res.data.localNews)
        setADS(res.data.adData)

        const dataArray = new FormData()
        dataArray.append("stateId", res.data.CustomerResult.stateId)
        axios
          .post(URLS.GetIdDistrict, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setDistrict(res.data.Districts)
          })

        const dataArray1 = new FormData()
        dataArray1.append("districtId", res.data.CustomerResult.districtId)
        axios
          .post(URLS.GetIdCity, dataArray1, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setCity(res.data.constResult)
          })
      })
  }

  const history = useHistory()

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = news.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(news.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(5)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber * listPerPage1
  const lists1 = ADS.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(ADS.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const Agentid1 = data => {
    sessionStorage.setItem("Viewusernewsid", data._id)
    history.push("/ViewuserNews")
  }

  const Agentid2 = data => {
    sessionStorage.setItem("Viewuseradsid", data._id)
    history.push("/ViewuserAds")
  }

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts1(e.target.value)
  }

  const getDistricts1 = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.constResult)
      })
  }

  const [Files, setFiles] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditDep()
  }
  const EditDep = () => {
    var token = datas
    var formid = form._id

    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("phone", form.phone)
    dataArray.append("address", form.address)
    dataArray.append("stateId", form.stateId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("constituencyId", form.constituencyId)
    dataArray.append("pinCode", form.pinCode)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("profilePic", Files[i])
    }
    axios
      .put(URLS.EditOneDetails + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetuserDetails()
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm1 = () => {
    setFiles({
      image: "",
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="PaperBoys" breadcrumbItem="View User Details" />
          <Row className="mb-2">
            <Col md="12">
              <Button
                className="m-1"
                style={{ float: "right" }}
                onClick={() => {
                  history.goBack()
                }}
                color="primary"
              >
                {" "}
                <i className="bx bx-left-arrow-alt" /> Back
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="12">
                      <div className="text-primary p-3 mb-5"></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="profile-user-wid">
                    <div className="text-center">
                      <Label>
                        <img
                          src={URLS.Base + form.profilePic}
                          type="button"
                          style={{ width: "100px" }}
                          alt="profilepic"
                          className="img-thumbnail rounded-circle"
                        />
                      </Label>
                    </div>
                  </div>
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-user-circle font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold"> Name: </h6>
                          <span className="text-muted">{form.name} </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-phone font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold"> Number:</h6>
                          <span className="text-muted">{form.phone}</span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex">
                        <i className="bx bx-envelope font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Email:</h6>
                          {form.email}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-buildings font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">State</h6>
                          {form.stateName}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-carousel font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">District</h6>
                          {form.districtName}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-store-alt font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Constituencies</h6>
                          {form.constituencyName}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-home font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Address</h6>
                          {form.address}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex ">
                        <i className="bx bx-bookmark font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Status :</h6>
                          <span className="text-muted"> {form.status}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>

            <Col lg={8}>
              <Card>
                {" "}
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "5",
                        })}
                        onClick={() => {
                          toggle1("5")
                        }}
                      >
                        Profile Update
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "6",
                        })}
                        onClick={() => {
                          toggle1("6")
                        }}
                      >
                        News
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab1 === "7",
                        })}
                        onClick={() => {
                          toggle1("7")
                        }}
                      >
                        Ads
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab1} className="p-3 text-muted">
                    <TabPane tabId="5">
                      <Form
                        onSubmit={e => {
                          handleSubmit1(e)
                        }}
                      >
                        <h5 className="mb-4 mt-4">Edit Details</h5>
                        <Row>
                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Username <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="name"
                                value={form.name}
                                placeholder="Enter Name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                required
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                placeholder="Enter Email"
                                type="email"
                                className="form-control"
                                id="validationCustom01"
                                name="email"
                                value={form.email}
                                onChange={e => {
                                  handleChange(e)
                                }}
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Phone No <span className="text-danger">*</span>
                              </Label>
                              <Input
                                placeholder="Enter Number"
                                type="phone"
                                className="form-control"
                                id="validationCustom01"
                                name="phone"
                                value={form.phone}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>

                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label> State</Label>{" "}
                              <span className="text-danger">*</span>
                              <select
                                value={form.stateId}
                                name="stateId"
                                onChange={e => {
                                  Optionchange(e)
                                }}
                                required
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {states.map((data, key) => {
                                  return (
                                    <option key={key} value={data._id}>
                                      {data.title}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label> District</Label>{" "}
                              <span className="text-danger">*</span>
                              <select
                                value={form.districtId}
                                name="districtId"
                                onChange={e => {
                                  Optionchange1(e)
                                }}
                                required
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {District.map((data, key) => {
                                  return (
                                    <option key={key} value={data._id}>
                                      {data.title}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>

                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label> City</Label>{" "}
                              <span className="text-danger">*</span>
                              <select
                                value={form.constituencyId}
                                name="constituencyId"
                                onChange={e => {
                                  handleChange(e)
                                }}
                                required
                                className="form-select"
                              >
                                <option value="">Select</option>
                                {City.map((data, key) => {
                                  return (
                                    <option key={key} value={data._id}>
                                      {data.title}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>

                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Pincode <span className="text-danger">*</span>
                              </Label>
                              <Input
                                placeholder="Enter Number"
                                type="number"
                                className="form-control"
                                id="validationCustom01"
                                name="pinCode"
                                value={form.pinCode}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>

                          <Col md={6} className="mt-3">
                            <Label htmlFor="validationCustom01">
                              Status <span className="text-danger">*</span>
                            </Label>
                            <select
                              name="status"
                              value={form.status}
                              onChange={e => {
                                handleChange(e)
                              }}
                              className="form-select"
                            >
                              <option value="active">Active</option>
                              <option value="inactive">In Active</option>
                            </select>
                          </Col>
                          <Col md={6} className="mt-3">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                User Profile
                              </Label>
                              <Input
                                type="file"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter image"
                                name="image"
                                value={Files.image}
                                onChange={changeHandler}
                              />
                            </div>{" "}
                          </Col>

                          <Col md={6} className="mt-3">
                            <Label htmlFor="validationCustom01">
                              Address <span className="text-danger">*</span>
                            </Label>
                            <textarea
                              placeholder="Enter Address"
                              type="number"
                              className="form-control"
                              id="validationCustom01"
                              name="address"
                              value={form.address}
                              onChange={e => {
                                handleChange(e)
                              }}
                            />
                          </Col>
                        </Row>

                        <div style={{ float: "right" }} className="mt-5">
                          <Button
                            style={{ width: "150px" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </TabPane>

                    <TabPane tabId="6">
                      <div className="table-rep-plugin  table-responsive">
                        <Table hover className="table table-bordered ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>IMAGE</th>
                              <th>HeadLine</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th style={{ width: "80px" }}> Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 5 + key + 6}</th>
                                <td>
                                  {data.imageType == "false" ? (
                                    <>
                                      <video
                                        controls
                                        style={{ width: "150px" }}
                                        src={URLS.Base + data.image}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                       style={{ width: "150px" }}
                                        src={URLS.Base + data.image}
                                      />
                                    </>
                                  )}
                                </td>
                                <td>{data.headLine}</td>
                                <td>{data.description.slice(0, 20) + "..."}</td>
                                <td>{data.status}</td>
                                <td>
                                  {" "}
                                  <Button
                                    onClick={() => {
                                      Agentid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      {" "}
                      <div className="table-rep-plugin  table-responsive">
                        <Table hover className="table table-bordered ">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Date/Time</th>
                              <th>IMAGE</th>
                              <th>Name</th>
                              <th>Link</th>
                              <th style={{ width: "100px" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr key={key}>
                                <th>{(pageNumber1 - 1) * 5 + key + 6}</th>
                                <td>{data.logCreatedDate.slice(0, 10)} </td>
                                <td>
                                  <img
                                    style={{ width: "100px" }}
                                    src={URLS.Base + data.image}
                                  />
                                </td>
                                <td>{data.createrName}</td>
                                <td>{data.link.slice(0, 20) + "..."}</td>

                                <td>
                                  <Button
                                    onClick={() => {
                                      Agentid2(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-3" style={{ float: "right" }}>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount1}
                          onPageChange={changePage1}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists1.length}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewUser
