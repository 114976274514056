const Url = "https://api.paperboys.co.in/"

export const URLS = {
  Base: Url,

  //Dashboard
  GetDashboard: Url + "v1/paperapi/admin/auth/getdashboard",

  //profile
  getProfile: Url + "v1/paperapi/admin/auth/getprofile",
  UpdateProfile: Url + "v1/paperapi/admin/auth/updateProfile",
  UpdateImage: Url + "v1/paperapi/admin/auth/updateprofileimage",
  ChangePass: Url + "v1/paperapi/admin/auth/changepass",

  //Departments
  AddDepartment: Url + "v1/paperapi/admin/department/adddepart",
  GetDepartment: Url + "v1/paperapi/admin/department/getalldepartment",
  GetDepartmentSearch:
    Url + "v1/paperapi/admin/department/getalldepartment?searchQuery=",
  UpdateDepartment: Url + "v1/paperapi/admin/department/editdepartment",
  DeleteDepartment: Url + "v1/paperapi/admin/department/deletedepartment",
  GetOneDepartment: Url + "v1/paperapi/admin/department/getdepartment/",

  //Role
  UpdateRole: Url + "v1/paperapi/admin/department/editrolepermit/",

  //GetAllCustomers
  GetAllCustomers: Url + "v1/paperapi/admin/customer/getall",
  GetAllCustomerSearch: Url + "v1/paperapi/admin/customer/getall?searchQuery=",
  GetOneDetails: Url + "v1/paperapi/admin/customer/getone",
  GetOneAdsDetails: Url + "v1/paperapi/admin/advertise/getdetails",
  GetOneNewsDetails: Url + "v1/paperapi/admin/localnews/newsdetails",
  EditOneDetails: Url + "v1/paperapi/admin/customer/editcustomer",
  BlockOneDetails: Url + "v1/paperapi/admin/customer/editstatus/",

  //Staff
  AddStaff: Url + "v1/paperapi/admin/auth/addstaff",
  GetStaff: Url + "v1/paperapi/admin/auth/getallstaff",
  GetStaffSearch: Url + "v1/paperapi/admin/auth/getallstaff?searchQuery=",
  UpdateStaff: Url + "v1/paperapi/admin/auth/editstaff/",
  InActiveStaff: Url + "v1/paperapi/admin/auth/deletestaff/",

  //DigitalNewsPaper
  AddDigitalNewsPaper: Url + "v1/paperapi/admin/digitalnews/addpaper",
  GetDigitalNewsPaper: Url + "v1/paperapi/admin/digitalnews/getall",
  GetDigitalNewsPaperSearch:
    Url + "v1/paperapi/admin/digitalnews/getall?searchQuery=",
  UpdateDigitalNewsPaper: Url + "v1/paperapi/admin/digitalnews/editpaper/",
  DeleteDigitalNewsPaper: Url + "v1/paperapi/admin/digitalnews/deletepaper/",
  GetOneDigitalNewsPaper: Url + "v1/paperapi/admin/digitalnews/getone/",

  //TvNews
  AddTvNews: Url + "v1/paperapi/admin/tvnews/addnews",
  GetTvNews: Url + "v1/paperapi/admin/tvnews/getall",
  GetTvNewsSearch: Url + "v1/paperapi/admin/tvnews/getall?searchQuery=",
  UpdateTvNews: Url + "v1/paperapi/admin/tvnews/editnews/",
  DeleteTvNews: Url + "v1/paperapi/admin/tvnews/deletenews/",
  GetOneTvNews: Url + "v1/paperapi/admin/tvnews/getone/",

  //settings/Faqs
  AddFAQ: Url + "v1/paperapi/admin/faq/addfaq",
  GetFAQ: Url + "v1/paperapi/admin/faq/getall",
  UpdateFAQ: Url + "v1/paperapi/admin/faq/editfaq",
  DeleteFAQ: Url + "v1/paperapi/admin/faq/deletefaq",

  //settings/State
  AddState: Url + "v1/paperapi/admin/state/addstate",
  GetState: Url + "v1/paperapi/admin/state/getall",
  GetStateSearch: Url + "v1/paperapi/admin/state/getall?searchQuery=",
  DeleteState: Url + "v1/paperapi/admin/state/deletestate/",
  UpdateState: Url + "v1/paperapi/admin/state/editstate",
  DropdownStates: Url + "v1/paperapi/admin/state/getallfordropdown",

  //settings/Pincode
  AddPincode: Url + "v1/paperapi/admin/pincode/addpincode",
  GetPincode: Url + "v1/paperapi/admin/pincode/getall",
  GetPincodeSearch: Url + "v1/paperapi/admin/pincode/getall?searchQuery=",
  UpdatePincode: Url + "v1/paperapi/admin/pincode/editpincode/",
  DeletePincode: Url + "v1/paperapi/admin/pincode/deletepincode/",

  //settings/District
  AddDistrict: Url + "v1/paperapi/admin/district/adddist",
  GetDistrict: Url + "v1/paperapi/admin/district/getall",
  GetDistrictSearch: Url + "v1/paperapi/admin/district/getall?searchQuery=",
  DeleteDistrict: Url + "v1/paperapi/admin/district/deletedist",
  UpdateDistrict: Url + "v1/paperapi/admin/district/editdist",
  GetIdDistrict: Url + "v1/paperapi/admin/district/getallstate",

  //settings/City
  AddCity: Url + "v1/paperapi/admin/constituency/addconstit",
  GetCity: Url + "v1/paperapi/admin/constituency/getall",
  GetCitySearch: Url + "v1/paperapi/admin/constituency/getall?searchQuery=",
  UpdateCity: Url + "v1/paperapi/admin/constituency/editconstit",
  InActiveCity: Url + "v1/paperapi/admin/constituency/deleteconstit",
  GetIdCity: Url + "v1/paperapi/admin/constituency/getallbydist",

  //settings/Language
  AddLanguage: Url + "v1/paperapi/admin/language/addlang",
  GetLanguage: Url + "v1/paperapi/admin/language/getall",
  DeleteLanguage: Url + "v1/paperapi/admin/language/deletelang/",
  UpdateLanguage: Url + "v1/paperapi/admin/language/editlang",
  GetLanguageSearch: Url + "v1/paperapi/admin/language/getall?searchQuery=",

  //settings/Notifications
  AddNotifications: Url + "v1/paperapi/admin/notification/addnotification",
  GetNotifications: Url + "v1/paperapi/admin/notification/getall",
  DeleteNotifications: Url + "v1/paperapi/admin/notification/delete/",
  GetNotificationsSearch:
    Url + "v1/paperapi/admin/notification/getall?searchQuery=",

  //settings/settings
  Getsettings: Url + "v1/paperapi/admin/setting/getdetails",
  Updatesettings: Url + "v1/paperapi/admin/setting/editsetting",

  //Popup/settings
  GetPopup: Url + "v1/paperapi/admin/popupad/getone",
  UpdatePopup: Url + "v1/paperapi/admin/popupad/editpopup/",

  //get all pending local news
  Getpendinglocalnews: Url + "v1/paperapi/admin/localnews/getallpending",
  GetpendinglocalnewsSearch:
    Url + "v1/paperapi/admin/localnews/getallpending?searchQuery=",
  GetpendinglocalnewsView: Url + "v1/paperapi/admin/localnews/getpending",

  //get all approved  local news
  Getapprovedlocalnews: Url + "v1/paperapi/admin/localnews/getallapproved",
  GetapprovedlocalnewsSearch:
    Url + "v1/paperapi/admin/localnews/getallapproved?searchQuery=",
  GetApprovedlocalnewsView: Url + "v1/paperapi/admin/localnews/getapproved",

  //get all rejected  local news
  Getrejectedlocalnews: Url + "v1/paperapi/admin/localnews/getallrejected",
  GetrejectedlocalnewsSearch:
    Url + "v1/paperapi/admin/localnews/getallrejected?searchQuery=",
  GetRejectlocalnewsView: Url + "v1/paperapi/admin/localnews/getrejected",

  // approve localnews
  Approvelocalnews: Url + "v1/paperapi/admin/localnews/approvelocalnews",

  // reject localnews
  rejectlocalnews: Url + "v1/paperapi/admin/localnews/rejectlocalnews",

  // Report localnews
  Reportlocalnews: Url + "v1/paperapi/admin/newscomplaint/getall",

  //get all pending Advertisements
  GetpendingAdvertisements: Url + "v1/paperapi/admin/advertise/getallpending",
  GetpendingAdvertisementsSearch:
    Url + "v1/paperapi/admin/advertise/getallpending?searchQuery=",
  GetpendingAdvertisementsView: Url + "v1/paperapi/admin/advertise/getpending",

  //get all approve  Advertisements
  GetapproveAdvertisements: Url + "v1/paperapi/admin/advertise/getallapproved",
  GetapproveAdvertisementsSearch:
    Url + "v1/paperapi/admin/advertise/getallapproved?searchQuery=",
  GetapproveAdvertisementsView: Url + "v1/paperapi/admin/advertise/getapproved",

  //get all rejected  Advertisements
  GetrejectedAdvertisements: Url + "v1/paperapi/admin/advertise/getallrejected",
  GetrejectedAdvertisementsSearch:
    Url + "v1/paperapi/admin/advertise/getallrejected?searchQuery=",
  GetrejectedAdvertisementsView:
    Url + "v1/paperapi/admin/advertise/getrejected",

  // approve Advertisement
  ApproveAdvertisement: Url + "v1/paperapi/admin/advertise/approve",

  // Reject Advertisement
  RejectAdvertisement: Url + "v1/paperapi/admin/advertise/reject",

  // get all complaints box
  getallcomplaints: Url + "v1/paperapi/admin/appcomplaint/getall",
  getallcomplaintssearch:
    Url + "v1/paperapi/admin/appcomplaint/getall?searchQuery=",

  Updateallcomplaints: Url + "v1/paperapi/admin/appcomplaint/updatestatus",

  GetlanguageId: Url + "v1/paperapi/admin/language/getone",

  updatelanguageId: Url + "v1/paperapi/admin/language/editlangkeywords",

  AddLocalNews: Url + "v1/paperapi/admin/localnews/addnews",

  EditLocalNews: Url + "v1/paperapi/admin/localnews/editnews",

  GetDashboard: Url + "v1/paperapi/admin/auth/getdashboard",

  AddAdvertising: Url + "v1/paperapi/admin/advertise/addadvertise",

  EditAdvertising: Url + "v1/paperapi/admin/advertise/editad",

  GetGeneralLocalNews: Url + "v1/paperapi/admin/localnews/newsdetails",

  GetGeneralAdvertise: Url + "v1/paperapi/admin/advertise/getdetails",

  //Settings
  AddTerms: Url + "v1/paperapi/admin/termscondition/addterms",
  GetTerms: Url + "v1/paperapi/admin/termscondition/getdetails",
  UpdateTerms: Url + "v1/paperapi/admin/termscondition/editterms/",
  DeleteTerms: Url + "v1/paperapi/admin/termscondition/deleteterms/",
  AddAbout: Url + "v1/paperapi/admin/aboutus/addabout",
  GetAbout: Url + "v1/paperapi/admin/aboutus/getall",
  UpdateAbout: Url + "v1/paperapi/admin/aboutus/editabout/",
  DeleteAbout: Url + "v1/paperapi/admin/aboutus/deleteabout/",
  Addprivacypolicy: Url + "v1/paperapi/admin/privacypolicy/addpolicy",
  Getprivacypolicy: Url + "v1/paperapi/admin/privacypolicy/getall",
  Updateprivacypolicy: Url + "v1/paperapi/admin/privacypolicy/editpolicy/",
  Deleteprivacypolicy:
    Url + "v1/paperapi/admin/privacypolicy/deleteprivacypolicy/",

  DeleteNews: Url + "v1/paperapi/admin/localnews/deletenews/",
  DeleteAds: Url + "v1/paperapi/admin/advertise/deletead/",

  //Newspaper
  AddNewspaper: Url + "v1/paperapi/admin/vendor/addNewspaperType",
  GetNewspaper: Url + "v1/paperapi/admin/vendor/getallNewspapers",
  GetNewspaperSearch:
    Url + "v1/paperapi/admin/vendor/getallNewspapers?searchQuery=",
  UpdateNewspaper: Url + "v1/paperapi/admin/vendor/editNewspaperType",
  DeleteNewspaper: Url + "v1/paperapi/admin/vendor/deleteNewspaper",

  //GetNewspaperBooking
  GetNewspaperBooking: Url + "v1/paperapi/admin/vendor/getcustomerBookings",
  GetNewspaperBookingSearch:
    Url + "v1/paperapi/admin/vendor/getcustomerBookings?searchQuery=",
  GetNewspaperBookingId:
    Url + "v1/paperapi/admin/vendor/getcustomerBookingsById",

  //Vendors
  AddVendors: Url + "v1/paperapi/admin/vendor/createVendor",
  GetVendors: Url + "v1/paperapi/admin/vendor/getAllVendors",
  GetVendorsSearch: Url + "v1/paperapi/admin/vendor/getAllVendors?searchQuery=",
  UpdateVendors: Url + "v1/paperapi/admin/vendor/editVendor",
  InActiveVendors: Url + "v1/paperapi/admin/vendor/updateVendorStatus/",

  //Job
  AddJob: Url + "v1/paperapi/admin/job/addJob",
  GetJob: Url + "v1/paperapi/admin/job/getApprovedJobs",
  GetoneJob: Url + "v1/paperapi/admin/job/getJobById",
  GetJobSearch: Url + "v1/paperapi/admin/job/getApprovedJobs?searchQuery=",
  UpdateJob: Url + "v1/paperapi/admin/job/editJob",
  DeleteJob: Url + "v1/paperapi/admin/job/deletejob",
  ApprovedJob: Url + "v1/paperapi/admin/job/jobApproval",
  PendingJob: Url + "v1/paperapi/admin/job/getallPendingJobs",
  PendingJobSearch:
    Url + "v1/paperapi/admin/job/getallPendingJobs?searchQuery=",
  StatusJob: Url + "v1/paperapi/admin/job/getallPendingJobs",
  UpdatepriorityJob: Url + "v1/paperapi/admin/job/editPriority",

  //Classified
  AddClassified: Url + "v1/paperapi/admin/classified/addclassified",
  GetClassified: Url + "v1/paperapi/admin/classified/getApprovedclassifieds",
  GetoneClassified: Url + "v1/paperapi/admin/classified/get-classified",
  GetClassifiedSearch:
    Url + "v1/paperapi/admin/classified/getApprovedclassifieds?searchQuery=",
  UpdateClassified: Url + "v1/paperapi/admin/classified/editclassified",
  ApprovedClassified: Url + "v1/paperapi/admin/classified/classifiedApproval",
  DeleteClassified:
    Url + "v1/paperapi/admin/classified/changeClassifiedStatus/",
  PendingClassified:
    Url + "v1/paperapi/admin/classified/getAllPendingclassifieds",
  PendingClassifiedSearch:
    Url + "v1/paperapi/admin/classified/getAllPendingclassifieds?searchQuery=",
  UpdatepriorityClassified: Url + "v1/paperapi/admin/classified/editPriority",

  //ServiceCategory
  AddServiceCategory: Url + "v1/paperapi/admin/classified/addCategory",
  GetServiceCategory: Url + "v1/paperapi/admin/classified/getallcategory",
  GetServiceCategorySearch:
    Url + "v1/paperapi/admin/classified/getallcategory?searchQuery=",
  UpdateServiceCategory: Url + "v1/paperapi/admin/classified/editCategory",
  DeleteServiceCategory:
    Url + "v1/paperapi/admin/classified/updateCategoryStatus",
}
