import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast, ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { URLS } from "Url"
import axios from "axios"
import Select from "react-select"

function AddVendors() {
  const [states1, setstates1] = useState([])
  const [District, setDistrict] = useState([])
  const [sts, setsts] = useState([])
  const [dts, setdts] = useState([])
  const [form, setform] = useState([])
  const [files, setFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const handleDrop = acceptedFiles => {
    const validatedFiles = acceptedFiles.map(file => {
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png"]
      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG  are allowed.")
        return null
      }
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize(file.size),
      })
    })

    const filteredFiles = validatedFiles.filter(file => file !== null)
    setFiles(filteredFiles)
  }

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const [selectedMulti, setselectedMulti] = useState([])

  const options = [
    { value: "Telugu", label: "Telugu" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
  ]

  function handleMulti(data) {
    setselectedMulti(Array.isArray(data) ? data : [])
  }

  const history = useHistory()
  const Adddealer = () => {
    const selectedValuesString = selectedMulti
      .map(option => option.value)
      .join(", ")

    var token = datas
    const dataArray = new FormData()
    dataArray.append("companyName", form.companyName)
    dataArray.append("companyNo", form.companyNo)
    dataArray.append("companyEmail", form.companyEmail)
    dataArray.append("companyLocation", form.companyLocation)
    dataArray.append("jobTitle", form.jobTitle)
    dataArray.append("jobCategory", form.jobCategory)
    dataArray.append("NumberOfOpenings", form.NumberOfOpenings)
    dataArray.append("jobLocation", form.jobLocation)
    dataArray.append("language", form.language)
    dataArray.append("jobType", form.jobType)
    dataArray.append("maxSalary", form.maxSalary)
    dataArray.append("qualification", form.qualification)
    dataArray.append("minSalary", form.minSalary)
    dataArray.append("interviewType", form.interviewType)
    dataArray.append("experience", form.experience)
    dataArray.append("fromTime", form.fromTime)
    dataArray.append("toTime", form.toTime)
    dataArray.append("shift", form.shift)
    dataArray.append("jobHours", form.jobHours)
    dataArray.append("jobId", form._id)
    dataArray.append("company_disc", form.company_disc)

    if (sts.stateId == undefined || sts.stateId == "All") {
      dataArray.append("stateId", "All")
    } else {
      dataArray.append("stateId", sts.stateId)
    }
    if (
      dts.districtId == undefined ||
      sts.stateId == "All" ||
      dts.districtId == "All"
    ) {
      dataArray.append("districtId", "All")
    } else {
      dataArray.append("districtId", dts.districtId)
    }

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    axios
      .post(URLS.UpdateJob, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/Jobs",
              sessionStorage.setItem(
                "tost",
                "Job has been Updated successfully"
              )
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Jobid = sessionStorage.getItem("JobId")

  const GetOneOrder = () => {
    const data = { jobId: Jobid }
    var token = datas
    axios
      .post(URLS.GetoneJob, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        const jobData = res.data.data
        setform(jobData)
        const languages = Array.isArray(jobData.language)
          ? jobData.language
          : []
        setselectedMulti(languages)

        if (res.data.data.stateId.length > 1) {
          const c = { ...sts }
          c["stateId"] = "All"
          console.log("asddddddd")
          setsts(c)
        } else {
          setsts(res.data.data)
          console.log("mfmafmafmasmfpasm")
        }

        if (res.data.data.districtId.length > 1) {
          const c = { ...dts }
          c["districtId"] = "All"
          setdts(c)
        } else {
          setdts(res.data.data)
        }

        const dataArray = new FormData()
        dataArray.append("stateId", res.data.data.stateId)
        axios
          .post(URLS.GetIdDistrict, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setDistrict(res.data.Districts)
          })
      })
  }
  useEffect(() => {
    getstatess()
  }, [])

  const getstatess = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates1(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...sts }
    myUser[e.target.name] = e.target.value
    setsts(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...dts }
    myUser[e.target.name] = e.target.value
    setdts(myUser)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Edit Job" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <h5 className="text-primary">Edit Job</h5>
                <hr></hr>
                <Row className="mt-4">
                  <Col lg="8">
                    <Row>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Company Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Company Name"
                            required
                            value={form.companyName}
                            name="companyName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Contact Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Contact Number"
                            required
                            value={form.companyNo}
                            name="companyNo"
                            onChange={e => {
                              handlechange(e)
                            }}
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            onKeyPress={e => {
                              // Allow only numeric input
                              const charCode = e.which ? e.which : e.keyCode
                              if (charCode < 48 || charCode > 57) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Company Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Company Email"
                            required
                            value={form.companyEmail}
                            name="companyEmail"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Company Location
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Company Location"
                            required
                            value={form.companyLocation}
                            name="companyLocation"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3 ">
                          <Label for="basicpill-firstname-input1">
                            Description
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter Description"
                            required
                            value={form.company_disc}
                            name="company_disc"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>{" "}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="4">
                    {/* <div className="text-center m-2">
                      <h5>Business Log</h5>
                      <div className="w-50 m-auto">
                        <Dropzone onDrop={handleDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzones">
                              <div
                                {...getRootProps()}
                                className="dz-messages needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-2 LOT">
                                  <i
                                    className="display-4 bx bx-plus"
                                    style={{
                                      fontSize: "35px",
                                      paddingTop: "10%",
                                      color: "white",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {files.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <a
                                    href={f.preview}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="40"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </a>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div> */}
                    <div className="text-center m-2">
                      <h5 style={{ fontWeight: "bold" }}>Image </h5>

                      <div className="w-75 m-auto">
                        <Dropzone onDrop={handleDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Upload Image</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {files.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <a
                                    href={f.preview}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="40"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </a>
                                </div>
                              </Card>
                            )
                          })}
                        </div>

                        {/* <div>
                            <span
                              className="text-danger "
                              style={{ fontSize: "10px" }}
                            >
                              (height : 700 px * width : 500 px)
                            </span>
                          </div> */}
                      </div>
                    </div>
                  </Col>
                  <h5 className="text-primary mt-3">Job Details</h5>
                  <hr></hr>
                  <Col lg="12">
                    <Row>
                      <Col md={3}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Job Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Job Title"
                            required
                            value={form.jobTitle}
                            name="jobTitle"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Job Category <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Job Category"
                            required
                            value={form.jobCategory}
                            name="jobCategory"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Number Of Openings
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Number Of Openings"
                            required
                            value={form.NumberOfOpenings}
                            name="NumberOfOpenings"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Job Location <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Job Location"
                            required
                            value={form.jobLocation}
                            name="jobLocation"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Languages</Label>
                          <span className="text-danger">*</span>
                          <Select
                            name="Languages"
                            value={selectedMulti}
                            onChange={handleMulti}
                            options={options}
                            required
                            isMulti
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> Job Type </Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.jobType}
                            name="jobType"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Field Job">Field Job</option>
                            <option value="Office Job">Office Job</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Qualification Type </Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.qualification}
                            name="qualification"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Graduated">Graduated</option>
                            <option value="Under Graduated">
                              Under Graduated
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Intersted In Hiring From </Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.interviewType}
                            name="interviewType"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Walk in interview">
                              Walk in interview
                            </option>
                            <option value="Virtual interview">
                              Virtual interview
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Monthly Minium Salary </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Monthly Minium Salary"
                            required
                            value={form.minSalary}
                            name="minSalary"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Monthly Maximum Salary </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Monthly Maximum Salary"
                            required
                            value={form.maxSalary}
                            name="maxSalary"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Year Of Experience </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Experience"
                            required
                            value={form.experience}
                            name="experience"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>From Time </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter From Time"
                            required
                            value={form.fromTime}
                            name="fromTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>To Time </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter To Time"
                            required
                            value={form.toTime}
                            name="toTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> Shift Timings</Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.shift}
                            name="shift"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Day Shift">Day Shift</option>
                            <option value="Night Shift">Night Shift</option>
                            <option value="Any Shift">Any Shift</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> Job Hours</Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.jobHours}
                            name="jobHours"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> State</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={sts.stateId}
                            name="stateId"
                            onChange={e => {
                              Optionchange(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="All">All</option>
                            {states1.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.title}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      {sts.stateId == "All" ? (
                        ""
                      ) : (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label> District</Label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              value={dts.districtId}
                              name="districtId"
                              onChange={e => {
                                Optionchange1(e)
                              }}
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="All">All</option>
                              {District.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.title}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-4 mt-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
