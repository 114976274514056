import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import Select from "react-select"
import { URLS } from "../../Url"
import axios from "axios"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

const Staff = () => {
  const [show, setshow] = useState(false)
  const [show1, setshow1] = useState(false)

  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptions1, setSelectedOptions1] = useState([])

  const [address, setaddress] = useState("")
  const [state, setState] = useState("")
  const [district, setDistrict] = useState("")
  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })
  const handleSelects = async value => {
    setaddress(value)
    setform(prevForm => ({ ...prevForm, address: value }))
    setform1(prevForm1 => ({ ...prevForm1, address: value }))

    try {
      const results = await geocodeByAddress(value)
      const latLng = await getLatLng(results[0])
      setcoordinateds(latLng)

      const addressComponents = results[0].address_components
      const stateComponent = addressComponents.find(component =>
        component.types.includes("administrative_area_level_1")
      )
      const districtComponent = addressComponents.find(component =>
        component.types.includes("administrative_area_level_3")
      )

      setState(stateComponent ? stateComponent.long_name : "")
      setDistrict(districtComponent ? districtComponent.long_name : "")
    } catch (error) {
      console.error("Error:", error)
    }
  }

  const [form, setform] = useState([])
  const [users, setusers] = useState([])
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Get = () => {
    var token = datas
    axios
      .post(
        URLS.GetVendors,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setusers(res.data.data)
      })
  }

  const custsearch = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    const token = datas
    axios
      .post(
        URLS.GetVendorsSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setusers(res.data.data)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Add = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("email", form.email)
    dataArray.append("phone", form.phone)
    dataArray.append("address", form.address)
    dataArray.append("pincode", JSON.stringify(selectedOptions))
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    axios
      .post(URLS.AddVendors, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform("")
            Get()
            setshow(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Add()
  }

  const Update = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("email", form1.email)
    dataArray.append("phone", form1.phone)
    dataArray.append("vendorId", form1._id)
    dataArray.append("address", form1.address)
    dataArray.append("pincode", JSON.stringify(selectedOptions1))
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    axios
      .post(URLS.UpdateVendors, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            console.log(res.data)
            toast(res.data.message)
            setform1("")
            Get()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formeditsubmit = e => {
    e.preventDefault()
    Update()
  }

  const usedata = data => {
    setshow1(true)
    setform1(data)
    setSelectedOptions1(data.pincode)
    setaddress(data.address)
    setcoordinateds({
      lat: data.latitude,
      lng: data.longitude,
      address: data.address,
    })
  }

  useEffect(() => {
    Get()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = users.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(users.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [pincode, setpincode] = useState([])

  useEffect(() => {
    getpincode()
  }, [])

  const getpincode = () => {
    var token = datas
    axios
      .post(
        URLS.GetPincode,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setpincode(res.data.pincodes)
      })
  }

  const multis = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const multis1 = selectedOptions1 => {
    setSelectedOptions1(selectedOptions1)
  }

  const options = pincode.map(response => ({
    value: response._id,
    label: response.pincode,
  }))

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id

    axios
      .post(
        URLS.InActiveVendors,
        { vendorId: remid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    axios
      .post(
        URLS.InActiveVendors,
        { vendorId: remid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Get()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Vendor" />
          <Row>
            <Col>
              {show == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formsubmit(e)
                    }}
                  >
                    <h5>Add New Vendor</h5>
                    <Row>
                      <Col md="4">
                        <Label>Name</Label>
                        <span className="text-danger">*</span>
                        <Input
                          name="name"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.name}
                          required
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Col>
                      <Col md="4">
                        <Label>Email Id</Label>
                        <span className="text-danger">*</span>
                        <Input
                          name="email"
                          onChange={e => {
                            handleChange(e)
                          }}
                          value={form.email}
                          required
                          type="email"
                          placeholder="Enter Email"
                        />
                      </Col>
                      <Col md="4">
                        <Label>Mobile No</Label>
                        <span className="text-danger">*</span>
                        <div className="input-group mb-3">
                          <Input
                            name="phone"
                            onChange={e => {
                              handleChange(e)
                            }}
                            value={form.phone}
                            required
                            type="text"
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            onKeyPress={e => {
                              // Allow only numeric input
                              const charCode = e.which ? e.which : e.keyCode
                              if (charCode < 48 || charCode > 57) {
                                e.preventDefault()
                              }
                            }}
                            className="form-control"
                            placeholder="Enter Mobile No"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3 ">
                          <Label for="basicpill-firstname-input1">
                            PinCode
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            options={options}
                            placeholder="Enter PinCode"
                            value={selectedOptions}
                            onChange={multis}
                            isSearchable={true}
                          />
                        </div>
                      </Col>

                      {/* Google maps */}
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Address <span className="text-danger">*</span>
                          </Label>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setaddress}
                            onSelect={handleSelects}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div
                                key={suggestions.description}
                                className="form-group "
                              >
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter Your Address",
                                    className:
                                      "location-search-input form-control",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }

                                    return (
                                      // <></>
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-end">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              {show1 == true ? (
                <Card className="p-4">
                  <Form
                    onSubmit={e => {
                      formeditsubmit(e)
                    }}
                  >
                    <Row>
                      <Col md="4">
                        <Label>Name</Label>
                        <span className="text-danger">*</span>
                        <Input
                          className="mb-3"
                          value={form1.name}
                          name="name"
                          onChange={e => {
                            handleChange1(e)
                          }}
                          required
                          type="text"
                          placeholder="Enter Name"
                        />
                      </Col>
                      <Col md="4">
                        <Label>Email Id</Label>
                        <span className="text-danger">*</span>
                        <Input
                          value={form1.email}
                          name="email"
                          onChange={e => {
                            handleChange1(e)
                          }}
                          required
                          type="email"
                          placeholder="Enter Email"
                        />
                      </Col>
                      <Col md="4">
                        <Label>Mobile No</Label>
                        <span className="text-danger">*</span>
                        <div className="input-group mb-3">
                          <Input
                            value={form1.phone}
                            name="phone"
                            onChange={e => {
                              handleChange1(e)
                            }}
                            required
                            type="text"
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            onKeyPress={e => {
                              // Allow only numeric input
                              const charCode = e.which ? e.which : e.keyCode
                              if (charCode < 48 || charCode > 57) {
                                e.preventDefault()
                              }
                            }}
                            className="form-control"
                            placeholder="Enter Mobile No"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3 ">
                          <Label for="basicpill-firstname-input1">
                            PinCode
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            options={options}
                            placeholder="Enter PinCode"
                            value={selectedOptions1}
                            onChange={multis1}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <Label for="basicpill-firstname-input1">Address</Label>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setaddress}
                          onSelect={handleSelects}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                          }) => (
                            <div>
                              <Input
                                {...getInputProps({
                                  placeholder: "Address",
                                  className: "location-search-input",
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {suggestions.map(suggestion => (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style: { cursor: "pointer" },
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    {suggestion.description}
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Col>
                    </Row>
                    <div className="text-end mt-3">
                      <Button type="submit" color="success m-1" outline>
                        Submit <i className="bx bx-check-circle"></i>
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setshow1(!show1)
                        }}
                        color="danger m-1"
                        outline
                      >
                        Cancel <i className="bx bx-x-circle"></i>
                      </Button>
                    </div>
                  </Form>
                </Card>
              ) : (
                ""
              )}
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => {
                          setshow(!show)
                        }}
                        color="primary"
                      >
                        New Vendor <i className="bx bx-user-plus"></i>
                      </Button>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form.search}
                          onChange={custsearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover bordered responsive>
                      <thead>
                        <tr className="text-center">
                          <th>Sl No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile No</th>
                          <th>Address</th>
                          <th>Pincode</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th scope="row">
                              {(pageNumber - 1) * 5 + key + 6}
                            </th>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{data.address}</td>
                            <td>{data?.pincode?.label}</td>
                            <td>
                              {data.status == true ? (
                                <>Active</>
                              ) : (
                                <>InActive</>
                              )}
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  usedata(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>
                              {data.status == true ? (
                                <Button
                                  onClick={() => {
                                    manageDelete(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="danger"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="fas fa-user-lock"
                                  ></i>
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    manageDelete1(data)
                                  }}
                                  size="sm"
                                  className="m-1"
                                  outline
                                  color="success"
                                >
                                  <i
                                    style={{ fontSize: " 14px" }}
                                    className="fas fa-user-check"
                                  ></i>
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Col sm="12">
                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Staff
