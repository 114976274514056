import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {roles.DashView === true ? (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="fas fa-home"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.staffView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-wallet-alt"></i>
                    <span>{props.t("Staff")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {roles.departmentView === true ? (
                      <>
                        <li>
                          <Link to="/Departments">{props.t("Roles")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {roles.rolePermitView === true ? (
                      <>
                        <li>
                          <Link to="/Roles">
                            {props.t("Roles & Premissions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to="/Staff">{props.t("Staff")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.customerView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-user-pin"></i>
                    <span>{props.t("Users")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Users">{props.t(" Users List")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.localNewsView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-street-view"></i>
                    <span>{props.t("Local News")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {roles.localNewsAdd === true ? (
                      <>
                        <li>
                          <Link to="/AddLocalNews">
                            {props.t("Add Local News")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to="/LocalNews">{props.t("Pending News")}</Link>
                    </li>
                    <li>
                      <Link to="/ApprovedLocalNews">
                        {props.t("Approved  News")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/ReportNews">{props.t("Report  News")}</Link>
                    </li>
                    <li>
                      <Link to="/RejectLocalNews">
                        {props.t("Reject  News")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.digitalNewsView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-id-card"></i>
                    <span>{props.t("Digital News Paper")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/DigitalNews">
                        {props.t("Digital News Paper")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.advertiseView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-spreadsheet"></i>
                    <span>{props.t("Advertising")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {roles.advertiseAdd === true ? (
                      <>
                        <li>
                          <Link to="/AddAdvertising">
                            {props.t("Add Advertising ")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    <li>
                      <Link to="/AdvertisingList">
                        {props.t("Pending Advertising ")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/ApprovedAdvertisingList">
                        {props.t("Approved Advertising ")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/RejectAdvertising">
                        {props.t("Reject Advertising ")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.customerView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-briefcase-alt"></i>
                    <span>{props.t("Jobs")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/CreateJob">{props.t("Create Job")}</Link>
                    </li>
                    <li>
                      <Link to="/PendingJobs">{props.t("Pending Jobs")}</Link>
                    </li>
                    <li>
                      <Link to="/Jobs">{props.t("Approved Jobs")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.customerView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-book"></i>
                    <span>{props.t("Classified")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/ServiceCategory">
                        {props.t("Service Category")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/CreateClassified">
                        {props.t("Create Classified")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/PendindClassified">
                        {props.t("Pending Classified")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Classified">
                        {props.t("Approved Classified")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.customerView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-news"></i>
                    <span>{props.t("NewsPaper")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/NewsPaper">{props.t("NewsPaper")}</Link>
                    </li>
                    <li>
                      <Link to="/Vendors">{props.t("Vendor")}</Link>
                    </li>
                    <li>
                      <Link to="/Booking">{props.t("Bookings")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.tvNewsView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-tv"></i>
                    <span>{props.t("Tv News")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/TvNew">{props.t("Tv News")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.popupView === true ? (
              <>
                <li>
                  <Link to="/Popup">
                    <i className="bx bxs-image-alt"></i>
                    <span>{props.t("Popups")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.notificationView === true ? (
              <>
                <li>
                  <Link to="/Notifications">
                    <i className="fa fa-bell"></i>

                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.complaintBoxView === true ? (
              <>
                <li>
                  <Link to="/Complaintbox">
                    <i className="fas fa-box-open"></i>
                    <span>{props.t("Complaint Box")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}
            {roles.settingsView === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-cogs"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Languages">{props.t("Language")}</Link>
                    </li>
                    <li>
                      <Link to="/State">{props.t("State")}</Link>
                    </li>
                    <li>
                      <Link to="/District">{props.t("District")}</Link>
                    </li>
                    <li>
                      <Link to="/City">{props.t("Constituencies")}</Link>
                    </li>{" "}
                    <li>
                      <Link to="/PinCode">{props.t("Pin Code")}</Link>
                    </li>
                    <li>
                      <Link to="/Faqs">{props.t("Faqs")}</Link>
                    </li>
                    <li>
                      <Link to="/About">{props.t("About")}</Link>
                    </li>
                    <li>
                      <Link to="/PrivicyPolicy">
                        {props.t("Privacy Policy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Terms">{props.t("Terms & Conditions")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
