import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"
import { URLS } from "../../Url"
import axios from "axios"

const Dashboard = props => {
  const [dash, setdash] = useState([])

  const [series1, setseries1] = useState([])

  const [series2, setseries2] = useState([])

  const [series3, setseries3] = useState([])

  const [cus, setcus] = useState([])

  useEffect(() => {
    getdashdata()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getdashdata = () => {
    var token = datas

    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdash(res.data)
        setseries1(res.data.graphCustomers)
        setseries2(res.data.graphLocalNews)
        setseries3(res.data.graphAdvertises)
        setcus(res.data.customers)
      })
  }

  const reports = [
    {
      title: "Pending News",
      iconClass: "bx-copy-alt",
      description: dash.pendingNewsCount,
    },
    {
      title: "Pending Advertising",
      iconClass: "bx-archive-in",
      description: dash.pendingAdsCount,
    },
    {
      title: "Approved News",
      iconClass: "bx-purchase-tag-alt",
      description: dash.approvedNewsCount,
    },
    {
      title: "Approved Advertising",
      iconClass: "bx-purchase-tag-alt",
      description: dash.approvedAdsCount,
    },
  ]

  const series = [
    {
      name: "Customers",
      data: series1,
    },
    {
      name: "LocalNews",
      data: series2,
    },
    {
      name: "Advertises",
      data: series3,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "34%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    colors: ["#305D00", "#f1b44c", "#305D00"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: " (data)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission
  const roles1 = data.user.departementName

  return (
    <React.Fragment>
      {roles.DashView === true  || roles1 === "superadmin" ? (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs
                title={props.t("Dashboards")}
                breadcrumbItem={props.t("Dashboard")}
              />
              <Row>
                <Col xl="12">
                  <Row>
                    {reports.map((report, key) => (
                      <Col md="3" key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium" style={{fontSize:"10px"}}>
                                 <b>{report.title}</b> 
                                </p>
                                <h4 className="mb-0">{report.description}</h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx " + report.iconClass + " font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Card>
                    <CardBody>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="table-rep-plugin mt-2 table-responsive">
                        <h5>Latest Users</h5>
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Name </th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Status </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cus.map((data, key) => (
                              <tr className="text-center" key={key}>
                                <td>{key + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.address}</td>

                                <td>
                                  <span className="badge bg-primary">
                                    {data.status}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
