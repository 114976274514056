import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

function LanguageSettings() {
  const history = useHistory()

  const [form, setform] = useState({
    Search: "",
    SignUp: "",
    LetsSignYouIn: "",
    LetsSign: "",
    YouIn: "",
    MobileNumber: "",
    Password: "",
    RememberMe: "",
    ForgotPassword: "",
    LogIn: "",
    Skip: "",
    Create: "",
    AnAccount: "",
    CreateanAccount: "",
    FullName: "",
    Email: "",
    Address: "",
    State: "",
    District: "",
    Constituency: "",
    Pincode: "",
    ConfirmPassword: "",
    ByClickingonSignUpyouagreeto: "",
    OurTermsofService: "",
    ByClickingonSignUpyouagreetoOurTermsofServiceandPrivacy: "",
    LoadingAd: "",
    Welcome: "",
    Home: "",
    NewsStatus: "",
    About: "",
    AboutUs: "",
    PrivacyPolicy: "",
    TermsConditions: "",
    Share: "",
    Settings: "",
    Notifications: "",
    Ads: "",
    Add: "",
    Jobs: "",
    More: "",
    LocalNews: "",
    DigitalNews: "",
    TVNews: "",
    Language: "",
    Itemdeleted: "",
    AddYourChoice: "",
    News: "",
    Advertising: "",
    Taptoknowmore: "",
    AddNews: "",
    AddPhoto: "",
    Video: "",
    Here: "",
    Link: "",
    WhattheNews: "",
    Select: "",
    State: "",
    All: "",
    District: "",
    whenitshappend: "",
    MoreNews: "",
    Constituency: "",
    Headline: "",
    Profile: "",
    Edit: "",
    Save: "",
    Details: "",
    Profile: "",
    Edit: "",
    Details: "",
    Save: "",
    ExpireDate: "",
    From: "",
    Language: "",
    Your: "",
    Choise: "",
    ReadMore: "",
    Location: "",
    ps: "",
    submit: "",
    complaint: "",
    discription: "",
    loding: "",
    LoadingAds: "",
    Classified: "",
    NewsPaper: "",
    bookNewsPaper: "",
  })

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  useEffect(() => {
    getpendingnews()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Agentid = sessionStorage.getItem("Languageid")

  const getpendingnews = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("_id", Agentid)
    axios
      .post(URLS.GetlanguageId, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.language.keyword)
      })
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    EditDep()
  }

  const EditDep = () => {
    var token = datas

    const DATA = {
      Search: form.Search,
      SignUp: form.SignUp,
      LetsSignYouIn: form.LetsSignYouIn,
      LetsSign: form.LetsSign,
      YouIn: form.YouIn,
      MobileNumber: form.MobileNumber,
      Password: form.Password,
      RememberMe: form.RememberMe,
      ForgotPassword: form.ForgotPassword,
      LogIn: form.LogIn,
      Skip: form.Skip,
      Create: form.Create,
      AnAccount: form.AnAccount,
      CreateanAccount: form.CreateanAccount,
      FullName: form.FullName,
      Email: form.Email,
      Address: form.Address,
      State: form.State,
      District: form.District,
      Constituency: form.Constituency,
      Pincode: form.Pincode,
      ConfirmPassword: form.ConfirmPassword,
      ByClickingonSignUpyouagreeto: form.ByClickingonSignUpyouagreeto,
      ByClickingonSignUpyouagreetoOurTermsofServiceandPrivacy:
        form.ByClickingonSignUpyouagreetoOurTermsofServiceandPrivacy,

      Welcome: form.Welcome,
      Home: form.Home,
      NewsStatus: form.NewsStatus,
      About: form.About,
      AboutUs: form.AboutUs,
      PrivacyPolicy: form.PrivacyPolicy,
      TermsConditions: form.TermsConditions,
      Share: form.Share,
      Settings: form.Settings,
      Notifications: form.Notifications,
      Ads: form.Ads,
      Add: form.Add,
      Jobs: form.Jobs,
      More: form.More,
      LocalNews: form.LocalNews,
      DigitalNews: form.DigitalNews,
      TVNews: form.TVNews,
      Language: form.Language,
      Itemdeleted: form.Itemdeleted,
      AddYourChoice: form.AddYourChoice,
      News: form.News,
      Advertising: form.Advertising,
      Taptoknowmore: form.Taptoknowmore,
      AddNews: form.AddNews,
      AddPhoto: form.AddPhoto,
      Video: form.Video,
      Here: form.Here,
      Link: form.Link,
      WhattheNews: form.WhattheNews,
      Select: form.Select,
      All: form.All,
      whenitshappend: form.whenitshappend,
      MoreNews: form.MoreNews,
      Constituency: form.Constituency,
      Headline: form.Headline,
      LoadingAds: form.LoadingAds,
      OurTermsofService: form.OurTermsofService,

      Profile: form.Profile,
      Edit: form.Edit,
      Details: form.Details,
      Save: form.Save,

      ExpireDate: form.ExpireDate,
      From: form.From,
      Language: form.Language,
      Your: form.Your,
      Choise: form.Choise,
      ReadMore: form.ReadMore,
      Location: form.Location,
      ps: form.ps,
      submit: form.submit,

      fromdate: form.fromdate,
      title: form.title,
      Logout: form.Logout,
      ComingSoon: form.ComingSoon,
      Searchhere: form.Searchhere,
      Points: form.Points,
      complaint: form.complaint,
      discription: form.discription,

      Classified: form.Classified,
      NewsPaper: form.NewsPaper,
      bookNewsPaper: form.bookNewsPaper,
    }

    axios
      .put(
        URLS.updatelanguageId + "/" + Agentid,
        { keyword: DATA },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            getpendingnews()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Update Language" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <Card>
                  <CardHeader className="bg-white">
                    <CardTitle>Update Language</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr className="w-50">
                            <th>S No</th>
                            <th>Name</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="w-50">
                            <td>1</td>
                            <td>Search</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Search"
                                value={form.Search}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td> Sign Up</td>

                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="LetsSignYouIn"
                                value={form.LetsSignYouIn}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td> Lets Sign</td>

                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="LetsSign"
                                value={form.LetsSign}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td> You In</td>

                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="YouIn"
                                value={form.YouIn}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td> Mobile Number</td>

                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="MobileNumber"
                                value={form.MobileNumber}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td> Password</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Password"
                                value={form.Password}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td> Remember Me</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="RememberMe"
                                value={form.RememberMe}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td> Forgot Password</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ForgotPassword"
                                value={form.ForgotPassword}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td> Log In</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="LogIn"
                                value={form.LogIn}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>Skip</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Skip"
                                value={form.Skip}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>11</td>
                            <td>Create an Account</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="CreateanAccount"
                                value={form.CreateanAccount}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>12</td>
                            <td> Full Name</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="FullName"
                                value={form.FullName}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>13</td>
                            <td>Email</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Email"
                                value={form.Email}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>14</td>
                            <td> Address</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Address"
                                value={form.Address}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>15</td>
                            <td> State</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="State"
                                value={form.State}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>16</td>
                            <td> District</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="District"
                                value={form.District}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>17</td>
                            <td> Confirm Password</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ConfirmPassword"
                                value={form.ConfirmPassword}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>18</td>
                            <td> Pincode</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Pincode"
                                value={form.Pincode}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>19</td>
                            <td>Our Terms of Service</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="OurTermsofService"
                                value={form.OurTermsofService}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td> By Clicking on Sign Up you agree to</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ByClickingonSignUpyouagreeto"
                                value={form.ByClickingonSignUpyouagreeto}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>21</td>
                            <td>
                              {" "}
                              By Clicking on Sign Up you agree to Our Terms of
                              Service and Privacy
                            </td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ByClickingonSignUpyouagreetoOurTermsofServiceandPrivacy"
                                value={
                                  form.ByClickingonSignUpyouagreetoOurTermsofServiceandPrivacy
                                }
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>22</td>
                            <td> Loading Ads</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="LoadingAds"
                                value={form.LoadingAds}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>23</td>
                            <td> Welcome</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Welcome"
                                value={form.Welcome}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>24</td>
                            <td> Home</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Home"
                                value={form.Home}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>25</td>
                            <td> News Status</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="NewsStatus"
                                value={form.NewsStatus}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>26</td>
                            <td> About</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="About"
                                value={form.About}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>27</td>
                            <td> Channels</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="AboutUs"
                                value={form.AboutUs}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>28</td>
                            <td> Privacy Policy</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="PrivacyPolicy"
                                value={form.PrivacyPolicy}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>29</td>
                            <td>Terms Conditions</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="TermsConditions"
                                value={form.TermsConditions}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>30</td>
                            <td> TV News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Share"
                                value={form.Share}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>31</td>
                            <td> Settings</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Settings"
                                value={form.Settings}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>32</td>
                            <td> Notifications</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Notifications"
                                value={form.Notifications}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>33</td>
                            <td> Ads</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Ads"
                                value={form.Ads}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>34</td>
                            <td> Add</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Add"
                                value={form.Add}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>35</td>
                            <td> Jobs</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Jobs"
                                value={form.Jobs}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>36</td>
                            <td> More</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="More"
                                value={form.More}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>37</td>
                            <td> Local News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="LocalNews"
                                value={form.LocalNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>38</td>
                            <td> Digital News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="DigitalNews"
                                value={form.DigitalNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>39</td>
                            <td> TV News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="TVNews"
                                value={form.TVNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>40</td>
                            <td> Language</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Language"
                                value={form.Language}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>41</td>
                            <td> Item deleted</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Itemdeleted"
                                value={form.Itemdeleted}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>42</td>
                            <td> Add Your Choice</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="AddYourChoice"
                                value={form.AddYourChoice}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>43</td>
                            <td> News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="News"
                                value={form.News}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>44</td>
                            <td> Tap to know More</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Taptoknowmore"
                                value={form.Taptoknowmore}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>45</td>
                            <td> Add News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="AddNews"
                                value={form.AddNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>46</td>
                            <td> Add Photo</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="AddPhoto"
                                value={form.AddPhoto}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>47</td>
                            <td>Video</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Video"
                                value={form.Video}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>48</td>
                            <td> Here</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Here"
                                value={form.Here}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>49</td>
                            <td> Head line</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Headline"
                                value={form.Headline}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>50</td>
                            <td> Link</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Link"
                                value={form.Link}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>51</td>
                            <td> What the News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="WhattheNews"
                                value={form.WhattheNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>52</td>
                            <td> Select</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Select"
                                value={form.Select}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>54</td>
                            <td> All</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="All"
                                value={form.All}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>56</td>
                            <td> when it's happend</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="whenitshappend"
                                value={form.whenitshappend}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>57</td>
                            <td> More News</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="MoreNews"
                                value={form.MoreNews}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>58</td>
                            <td>Constituency</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Constituency"
                                value={form.Constituency}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>59</td>
                            <td>Profile</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Profile"
                                value={form.Profile}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>60</td>
                            <td>Edit</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Edit"
                                value={form.Edit}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>61</td>
                            <td>Save</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Save"
                                value={form.Save}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>62</td>
                            <td>Details</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Details"
                                value={form.Details}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>63</td>
                            <td>Expire Date</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ExpireDate"
                                value={form.ExpireDate}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>64</td>
                            <td>From</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="From"
                                value={form.From}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>65</td>
                            <td>Choise</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Choise"
                                value={form.Choise}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>66</td>
                            <td>Read More</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ReadMore"
                                value={form.ReadMore}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>67</td>
                            <td>Location</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Location"
                                value={form.Location}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>68</td>
                            <td>Photo</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ps"
                                value={form.ps}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>69</td>
                            <td>Submit</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="submit"
                                value={form.submit}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>70</td>
                            <td>from date</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="fromdate"
                                value={form.fromdate}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>71</td>
                            <td>Title</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="title"
                                value={form.title}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>72</td>
                            <td>Log out</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Logout"
                                value={form.Logout}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>73</td>
                            <td>Coming Soon</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="ComingSoon"
                                value={form.ComingSoon}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>74</td>
                            <td>Search here</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Searchhere"
                                value={form.Searchhere}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>75</td>
                            <td>Points</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Points"
                                value={form.Points}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>76</td>
                            <td>Complaint</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="complaint"
                                value={form.complaint}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>77</td>
                            <td>Discription</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="discription"
                                value={form.discription}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>78</td>
                            <td>NewsPaper</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="NewsPaper"
                                value={form.NewsPaper}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>79</td>
                            <td>Classified</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="Classified"
                                value={form.Classified}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>80</td>
                            <td>Book News Paper</td>
                            <td>
                              {" "}
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter value"
                                name="bookNewsPaper"
                                value={form.bookNewsPaper}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div style={{ float: "right" }}>
                      <Button className="m-1" color="primary" type="submit">
                        Submit <i className="fas fa-check-circle"></i>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default LanguageSettings
