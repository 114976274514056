import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"

function State() {
  const [modal_small, setmodal_small] = useState(false)
  const [modal_small1, setmodal_small1] = useState(false)
  const [NewsPaper, setNewsPaper] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  const [form3, setform3] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllNewsPapers()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = NewsPaper.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(NewsPaper.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const AddNewsPaper = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("name", form.name)
    dataArray.append("language", form.language)
    dataArray.append("price", form.price)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    axios
      .post(URLS.AddNewspaper, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllNewsPapers()
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditNewsPaper = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("name", form1.name)
    dataArray.append("language", form1.language)
    dataArray.append("price", form1.price)
    dataArray.append("newspaperId", form1._id)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("image", Files1[i])
    }

    axios
      .post(URLS.UpdateNewspaper, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllNewsPapers()
            setmodal_small(false)
            clearForm1()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteNewsPaper = data => {
    var token = datas
    var remid = { newspaperId: data._id }
    axios
      .post(URLS.DeleteNewspaper, remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllNewsPapers()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteNewsPaper(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddNewsPaper()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    EditNewsPaper()
  }

  const GetAllNewsPapers = () => {
    var token = datas
    axios
      .post(
        URLS.GetNewspaper,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNewsPaper(res.data.newspapers)
        setIsLoading(false)
      })
  }

  const clearForm1 = () => {
    setFiles1({
      image: "",
    })
  }

  const clearForm = () => {
    setform({
      name: "",
      language: "",
      price: "",
    })
    setFiles({
      image: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const getpopup1 = data => {
    setform3(data)
    tog_small1()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetNewspaperSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNewsPaper(res.data.newspapers)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission
  const roles1 = data.user.departementName

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="News Paper" />

          <Row>
            {roles.tvNewsAdd === true || roles1 === "superadmin" ? (
              <>
                <Col md={4}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add News Paper</CardTitle>
                    </CardHeader>
                    {isLoading == true ? (
                      <>
                        <div
                          style={{ zIndex: "9999999999999", height: "420px" }}
                          className="text-center mt-5 pt-5"
                        >
                          <Spinner
                            style={{ marginTop: "120px" }}
                            className="ms-2"
                            animation="border"
                            color="primary"
                            size="lg"
                          />{" "}
                          <div>Loading......</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <CardBody>
                          <Form
                            onSubmit={e => {
                              handleSubmit(e)
                            }}
                          >
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Title <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Title"
                                required
                                name="name"
                                value={form.name}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>

                            <div className="mb-3">
                              <Label> Language </Label>{" "}
                              <span className="text-danger">*</span>
                              <select
                                value={form.language}
                                name="language"
                                required
                                onChange={e => {
                                  handleChange(e)
                                }}
                                className="form-select"
                              >
                                <option value="">Select</option>
                                <option value="Telugu">Telugu</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Urdu">Urdu</option>
                              </select>
                            </div>

                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Image{" "}
                                <span
                                  className="text-danger "
                                  style={{ fontSize: "10px" }}
                                >
                                  (height : 100 px * width : 100px)
                                </span>
                              </Label>
                              <input
                                type="file"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter image"
                                required
                                name="image"
                                value={Files.image}
                                onChange={changeHandler}
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Price
                                <span className="text-danger">*</span>
                              </Label>
                              <input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Price"
                                required
                                name="price"
                                value={form.price}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>

                            <div style={{ float: "right" }}>
                              <Button color="primary" type="submit">
                                Submit <i className="fas fa-check-circle"></i>
                              </Button>
                            </div>
                          </Form>
                        </CardBody>
                      </>
                    )}
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
            {roles.tvNewsAdd === true || roles1 === "superadmin" ? (
              <>
                <Col md={8}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>News Paper List</CardTitle>
                    </CardHeader>

                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div className="table-responsive">
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>S No</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Language</th>
                                <th>Price</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 5 + key + 6}</td>
                                  <td>{data.name}</td>
                                  <td>
                                    {" "}
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.image}
                                    />
                                  </td>
                                  <td>{data.language}</td>
                                  <th>{data.price}</th>
                                  <td>
                                    {roles.tvNewsEdit === true ||
                                    roles1 === "superadmin" ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {roles.tvNewsDelete === true ||
                                    roles1 === "superadmin" ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="danger"
                                          outline
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </Container>
        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit News Paper
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Title <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Title"
                  required
                  name="name"
                  value={form1.name}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div className="mb-3">
                <Label> Language </Label> <span className="text-danger">*</span>
                <select
                  value={form1.language}
                  name="language"
                  required
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="Telugu">Telugu</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Urdu">Urdu</option>
                </select>
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Image :
                  <span className="text-danger">
                    <Button
                      onClick={() => {
                        getpopup1(form1.image)
                      }}
                      size="sm"
                      className="m-1"
                      outline
                      color="danger"
                    >
                      <i
                        style={{ fontSize: "12px" }}
                        className="fas fa-eye"
                      ></i>
                    </Button>
                  </span>
                </Label>
                <input
                  type="file"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter image"
                  name="image"
                  value={Files1.image}
                  onChange={changeHandler1}
                />
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Price
                  <span className="text-danger">*</span>
                </Label>
                <input
                  type="number"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Price"
                  required
                  name="price"
                  value={form1.price}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <Modal
          size="md"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Image View
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <img
              style={{ width: "100%", height: "400px" }}
              src={URLS.Base + form3}
            />
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
