import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  const [files, setFiles] = useState([])

  const [files1, setFiles1] = useState([])

  const [states1, setstates1] = useState([])

  const [District, setDistrict] = useState([])

  const [City, setCity] = useState([])

  const [sts, setsts] = useState([])
  
  const [dts, setdts] = useState([])

  const [cts, setcts] = useState([])


  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const handleDrop1 = acceptedFiles => {
    const maxSizeInBytes = 100 * 1024 * 1024 // 100 MB in bytes
    const validatedFiles = acceptedFiles.map(file => {
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["mp4", "mkv", "x-m4v", "webm", "avi", "3gpp"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast(
          "Invalid file format! Only can upload mp4, mkv, x-m4v, webm, avi, 3gpp video formats."
        )
        return null
      }

      if (file.size > maxSizeInBytes) {
        toast("File size exceeds the 100 MB limit.")
        return null
      }

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize1(file.size),
      })
    })

    const filteredFiles = validatedFiles.filter(file => file !== null)
    setFiles1(filteredFiles)
  }
  const formatFileSize1 = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const handleDrop = acceptedFiles => {
    const validatedFiles = acceptedFiles.map(file => {
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG  are allowed.")
        return null
      }

      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize(file.size),
      })
    })

    const filteredFiles = validatedFiles.filter(file => file !== null)
    setFiles(filteredFiles)
  }

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    var formid = form._id
    const dataArray = new FormData()
    dataArray.append("headLine", form.headLine)
    dataArray.append("summary", "")
    // dataArray.append("stateId", sts.stateId)
    // dataArray.append("districtId", dts.districtId)
    // dataArray.append("constituencyId", cts.constituencyId)

    if (sts.stateId == undefined || sts.stateId == "All") {
      dataArray.append("stateId", "All")
    } else {
      dataArray.append("stateId", sts.stateId)
    }
    if (
      dts.districtId == undefined ||
      sts.stateId == "All" ||
      dts.districtId == "All"
    ) {
      dataArray.append("districtId", "All")
    } else {
      dataArray.append("districtId", dts.districtId)
    }
    if (
      cts.constituencyId == undefined ||
      sts.stateId == "All" ||
      dts.districtId == "All" ||
      cts.constituencyId == "All"
    ) {
      dataArray.append("constituencyId", "All")
    } else {
      dataArray.append("constituencyId", cts.constituencyId)
    }

    dataArray.append("dateOfNews", form.dateOfNews)
    dataArray.append("description", form.description)
    dataArray.append("languageId", form.languageId)
    dataArray.append("link", form.link)
    dataArray.append("imageType", form.imageType)

    if (form.imageType == "false") {
      for (let i = 0; i < files.length; i++) {
        dataArray.append("image", files1[i])
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        dataArray.append("image", files[i])
      }
    }

    axios
      .put(URLS.EditLocalNews + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/LocalNews",
              sessionStorage.setItem(
                "tost",
                "News has been Updated successfully"
              )
            )
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handlechanges = e => {
    const myform = { ...cts }
    myform[e.target.name] = e.target.value
    setcts(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [states, setstates] = useState([])

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetLanguage,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.language)
      })
  }

  useEffect(() => {
    getstatess()
  }, [])

  const getstatess = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates1(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...sts }
    myUser[e.target.name] = e.target.value
    setsts(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...dts }
    myUser[e.target.name] = e.target.value
    setdts(myUser)
    getDistricts1(e.target.value)
  }

  const getDistricts1 = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.constResult)
      })
  }

  useEffect(() => {
    getpendingnews()
  }, [])

  const Agentid = sessionStorage.getItem("newsid")

  const getpendingnews = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("_id", Agentid)
    axios
      .post(URLS.GetpendinglocalnewsView, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.localNews)

        if (res.data.localNews.stateId.length > 1) {
          const c = { ...sts }
          c["stateId"] = "All"
          console.log("asddddddd")
          setsts(c)
        } else {
          setsts(res.data.localNews)
          console.log("mfmafmafmasmfpasm")
        }

        if (res.data.localNews.districtId.length > 1) {
          const c = { ...dts }
          c["districtId"] = "All"
          setdts(c)
        } else {
          setdts(res.data.localNews)
        }
        if (res.data.localNews.constituencyId.length > 1) {
          const c = { ...cts }
          c["constituencyId"] = "All"
          setcts(c)
        } else {
          setcts(res.data.localNews)
        }

        const dataArray = new FormData()
        dataArray.append("stateId", res.data.localNews.stateId)
        axios
          .post(URLS.GetIdDistrict, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setDistrict(res.data.Districts)
          })

        const dataArray1 = new FormData()
        dataArray1.append("districtId", res.data.localNews.districtId)
        axios
          .post(URLS.GetIdCity, dataArray1, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setCity(res.data.constResult)
          })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Edit News" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <div className="mb-3">
                      <Label> Language </Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.languageId}
                        name="languageId"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label>Upload Type</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.imageType}
                        name="imageType"
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="true">Image</option>
                        <option value="false">Video</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HeadLine <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HeadLine"
                        required
                        value={form.headLine}
                        name="headLine"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3 mt-3">
                      <Label for="basicpill-firstname-input1">
                        News <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter News"
                        required
                        value={form.description}
                        name="description"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    {form.imageType == "false" ? (
                      <div className="text-center m-4">
                        <h5 style={{ fontWeight: "bold" }}>Video </h5>

                        <div className="w-50 m-auto">
                          <Dropzone onDrop={handleDrop1}>
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  {...getRootProps()}
                                  className="dz-message needsclick mt-2"
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload video.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {files1.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <video
                                          data-dz-thumbnail=""
                                          height="40"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}

                            {form.imageType == "false" ? (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <video
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        src={URLS.Base + form.image}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {form.headLine}
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            ) : (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        src={URLS.Base + form.image}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {form.headLine}
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )}

                            <div>
                              <span
                                className="text-danger "
                                style={{ fontSize: "10px" }}
                              >
                                (height : 700 px * width : 500 px)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center m-4">
                        <h5 style={{ fontWeight: "bold" }}>Image </h5>

                        <div className="w-50 m-auto">
                          <Dropzone onDrop={handleDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  {...getRootProps()}
                                  className="dz-message needsclick mt-2"
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload Image.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {files.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <a
                                      href={f.preview}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="40"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                          {form.imageType == "false" ? (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <video
                                      data-dz-thumbnail=""
                                      height="40"
                                      className="avatar-sm rounded bg-light"
                                      src={URLS.Base + form.image}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {form.headLine}
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          ) : (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="40"
                                      className="avatar-sm rounded bg-light"
                                      src={URLS.Base + form.image}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {form.headLine}
                                    </Link>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )}
                          <div>
                            <span
                              className="text-danger "
                              style={{ fontSize: "10px" }}
                            >
                              (height : 700 px * width : 500 px)
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date of News <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter"
                        required
                        value={form.dateOfNews}
                        name="dateOfNews"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  {/* <Col md={4}>
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Link <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Link"
                        required
                        value={form.link}
                        name="link"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                  <Col md={4}>
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Summary <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Summary"
                        required
                        value={form.summary}
                        name="summary"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col> */}

                  <Col md={3}>
                    <div className="mb-3">
                      <Label> State</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={sts.stateId}
                        name="stateId"
                        onChange={e => {
                          Optionchange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="All">All</option>
                        {states1.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>

                  {sts.stateId == "All" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      <div className="mb-3">
                        <Label> District</Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={dts.districtId}
                          name="districtId"
                          onChange={e => {
                            Optionchange1(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          <option value="All">All</option>
                          {District.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                  )}

                  {sts.stateId == "All" || dts.districtId == "All" ? (
                    ""
                  ) : (
                    <Col md={3}>
                      <div className="mb-3">
                        <Label> Constituency </Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={cts.constituencyId}
                          name="constituencyId"
                          onChange={e => {
                            handlechanges(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          <option value="All">All</option>
                          {City.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                  )}

                  <Col md={6}>
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">Link</Label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Link"
                        required
                        value={form.link}
                        name="link"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-4 mt-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
