import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  ModalBody,
  Form,
  Label,Spinner
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import trash from "../../assets/images/trash.gif"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import Moment from "react-moment"

const ResponsiveTables = () => {
  const [form, setform] = useState({
    districtId: "",
    stateId: "",
    constituencyId: "",
  })

  const [isLoading, setIsLoading] = useState(true)

  const [userInCsv, setuserInCsv] = useState([])

  const [states1, setstates1] = useState([])

  const [District, setDistrict] = useState([])

  const [City, setCity] = useState([])

  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)
  const toggle2 = () => setshow2(!show2)

  const [form1, setform1] = useState([])

  const [plans, setplans] = useState([])
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", "")
    dataArray.append("stateId", "")
    dataArray.append("constituencyId", "")

    axios
      .post(URLS.GetAllCustomers, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setplans(res.data.customerResult)
        setuserInCsv(res.data.customerExcel)
        setIsLoading(false)
      })
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", "")
    dataArray.append("stateId", "")
    dataArray.append("constituencyId", "")

    axios
      .post(URLS.GetAllCustomerSearch + `${e.target.value}`, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.customerResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  const Agentid1 = data => {
    sessionStorage.setItem("Viewuserid", data._id)
    history.push("/ViewUser")
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you want block?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const dataArray = new FormData()
    dataArray.append("status", "inactive")
    axios
      .put(URLS.BlockOneDetails + remid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you want un block?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id
    const dataArray = new FormData()
    dataArray.append("status", "active")
    axios
      .put(URLS.BlockOneDetails + remid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission
  const roles1 = data.user.departementName

  const [filter, setfilter] = useState(false)

  const getfilter = e => {
    e.preventDefault()
    GetAgentFiliter()
  }

  const GetAgentFiliter = () => {
    var token = datas

    const dataArray = new FormData()

    if (form.districtId == "All") {
      dataArray.append("districtId", "")
    } else {
      dataArray.append("districtId", form.districtId)
    }
    if (form.stateId == "All") {
      dataArray.append("stateId", "")
    } else {
      dataArray.append("stateId", form.stateId)
    }
    if (form.constituencyId == "All") {
      dataArray.append("constituencyId", "")
    } else {
      dataArray.append("constituencyId", form.constituencyId)
    }

    axios
      .post(URLS.GetAllCustomers, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setplans(res.data.customerResult)
        setuserInCsv(res.data.customerExcel)
        hidefilter()
      })
  }

  const hidefilter = () => setfilter(false)

  const csvReport = {
    filename: "Users List",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"

    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const headers = [["S.No", "Name", "Email", "Phone", "State","District","Constancy","Status"]]

    const datas = plans.map((data, i) => [
      i + 1,
      data.name,
      data.email,
      data.phone,
      data.stateName,
      data.districtName,
      data.constituencyName,
      data.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: datas,
    }
    doc.autoTable(content)
    doc.save("Users.pdf")
  }

  useEffect(() => {
    getstatess()
  }, [])

  const getstatess = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates1(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts1(e.target.value)
  }

  const getDistricts1 = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.constResult)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Paper Boys" breadcrumbItem="User list" />

          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label> State</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.stateId}
                            name="stateId"
                            onChange={e => {
                              Optionchange(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="All">All</option>
                            {states1.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.title}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      {form.stateId == "All" ? (
                        ""
                      ) : (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label> District</Label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              value={form.districtId}
                              name="districtId"
                              onChange={e => {
                                Optionchange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="All">All</option>
                              {District.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.title}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                      )}
                      {form.stateId == "All" || form.districtId == "All" ? (
                        ""
                      ) : (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label> Constituency </Label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              value={form.constituencyId}
                              name="constituencyId"
                              onChange={e => {
                                handlechange(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="All">All</option>
                              {City.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.title}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                      )}
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="primary">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Card>
              {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <Spinner
                        style={{ marginTop: "120px" }}
                        className="ms-2"
                        animation="border"
                        color="primary"
                        size="lg"
                      />{" "}
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                <CardBody>
                  <div className="table-responsive">
                    <div className="col-sm-12">
                      <div style={{ float: "right" }}>
                        <Row>
                          <Col>
                            <div style={{ float: "right" }}>
                              <CSVLink {...csvReport}>
                                <button
                                  className="btn btn-success me-2"
                                  type="submit"
                                >
                                  <i className="fas fa-file-excel"></i> Excel
                                </button>
                              </CSVLink>
                              <Button
                                type="button"
                                className="btn btn-danger "
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>

                              <Button
                                className="m-1"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="primary"
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2 mb-5">
                      <div style={{ float: "left" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={planssearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Join Date/time</th>
                          <th>Name </th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>State</th>
                          <th>District</th>
                          <th>Constancy</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th>{(pageNumber - 1) * 5 + key + 6}</th>
                            <td><Moment format="DD/MM/YYYY">{data.logCreatedDate}</Moment> / <Moment format="hh:mm:ss" >{data.logCreatedDate}</Moment></td>
                            <td>{data.name}</td>
                            <td>{data.email}</td>
                            <td>{data.phone}</td>
                            <td>{data.stateName}</td>
                            <td>{data.districtName}</td>
                            <td>{data.constituencyName}</td>
                            <td>
                              {data.status == "active" ? (
                                <>
                                  <span className="badge rounded-pill bg-success">
                                    {data.status}
                                  </span>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <span className="badge rounded-pill bg-danger">
                                    {data.status}{" "}
                                  </span>
                                </>
                              )}
                            </td>

                            <td>
                              {roles.customerEdit === true ||
                              roles1 === "superadmin" ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      Agentid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}

                              {roles.customerEdit === true ||
                              roles1 === "superadmin" ? (
                                <>
                                  {data.status == "active" ? (
                                    <Button
                                      onClick={() => {
                                        manageDelete(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="danger"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-user-lock"
                                      ></i>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        manageDelete1(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="success"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-user-check"
                                      ></i>
                                    </Button>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
                </>
                )}
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
      <Modal size="sm" isOpen={show1} toggle={toggle} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want Block</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="sm" isOpen={show2} toggle={toggle2} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want UnBlock</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete1()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle2}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ResponsiveTables
