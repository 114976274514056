import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Resetpsw from "pages/Authentication/Resetpsw"
import Compareotp from "pages/Authentication/Compareotp"
import ForgetPassword from "pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Users
import Users from "../pages/Users/Users"
import ViewUser from "pages/Users/ViewUser"
import ViewuserAds from "pages/Users/ViewuserAds"

// Staff
import Departments from "pages/Staff/Departments"
import Roles from "pages/Staff/Roles"
import Staff from "pages/Staff/Staff"

//News
import LocalNews from "pages/LocalNews/LocalNews"
import ViewuserNews from "pages/Users/ViewuserNews"
import ReportNews from "pages/LocalNews/ReportNews"
import AddLocalNews from "pages/LocalNews/AddLocalNews"
import EditLocalNews from "pages/LocalNews/EditLocalNews"
import LocalNewsView from "pages/LocalNews/LocalNewsView"
import RejectLocalNews from "pages/LocalNews/RejectLocalNews"
import ApprovedLocalNews from "pages/LocalNews/ApprovedLocalNews"
import ViewRejectlocalNews from "pages/LocalNews/ViewRejectlocalNews"
import ViewApprovedLocalNews from "pages/LocalNews/ViewApprovedLocalNews"
import ViewRejectAdvertising from "pages/Advertising/ViewRejectAdvertising"
import ViewApprovedAdvertising from "pages/Advertising/ViewApprovedAdvertising"

//AdvertisingList
import ApprovedAdvertisingList from "pages/Advertising/ApprovedAdvertisingList"
import RejectAdvertising from "pages/Advertising/RejectAdvertising"
import AdvertisingList from "pages/Advertising/AdvertisingList"
import ViewAdvertising from "pages/Advertising/ViewAdvertising"
import AddAdvertising from "pages/Advertising/AddAdvertising"
import EditAdvertising from "pages/Advertising/EditAdvertising"

//Jobs
import CreateJob from "pages/Jobs/CreateJob"
import EditJob from "pages/Jobs/EditJob"
import ViewJob from "pages/Jobs/ViewJob"
import Jobs from "pages/Jobs/Jobs"
import Vendors from "pages/NewsPaper/Vendors"
import PendingJobs from "pages/Jobs/PendingJobs"
import PendindClassified from "pages/Classfied/PendindClassified"
import ServiceCategory from "pages/Jobs/ServiceCategory"

//Classified
import Classified from "pages/Classfied/Classified"
import CreateClassified from "pages/Classfied/CreateClassified"
import EditClassified from "pages/Classfied/EditClassified"
import ViewClassified from "pages/Classfied/ViewClassified"

//NewsPaper
import NewsPaper from "pages/NewsPaper/NewsPaper"
import Booking from "pages/NewsPaper/Booking"
import ViewBooking from "pages/NewsPaper/ViewBooking"

//DigitalNews
import TvNew from "pages/TvNews/TvNew"
import DigitalNews from "pages/DigitalNews/DigitalNews"

//Banners
import Banners from "../pages/Banners/Banners"
import Notifications from "pages/Notifications/Notifications"
import Complaintbox from "pages/ComplaintBox/ComplaintBox"

//Settings
import LanguageSettings from "pages/Settings/LanguageSettings"
import PrivicyPolicy from "pages/Settings/PrivicyPolicy"
import Languages from "pages/Settings/Languages"
import District from "pages/Settings/District"
import State from "pages/Settings/State"
import About from "pages/Settings/About"
import Terms from "pages/Settings/Terms"
import City from "pages/Settings/City"
import Faqs from "pages/Settings/Faqs"
import PinCode from "pages/Settings/PinCode"
import Json from "pages/Json"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //Staff
  { path: "/Roles", component: Roles },
  { path: "/Departments", component: Departments },
  { path: "/Staff", component: Staff },

  //Users
  { path: "/Users", component: Users },
  { path: "/ViewUser", component: ViewUser },

  //LocalNews
  { path: "/LocalNews", component: LocalNews },
  { path: "/ReportNews", component: ReportNews },
  { path: "/AddLocalNews", component: AddLocalNews },
  { path: "/ViewuserNews", component: ViewuserNews },
  { path: "/EditLocalNews", component: EditLocalNews },
  { path: "/LocalNewsView", component: LocalNewsView },
  { path: "/RejectLocalNews", component: RejectLocalNews },
  { path: "/ApprovedLocalNews", component: ApprovedLocalNews },
  { path: "/ViewRejectlocalNews", component: ViewRejectlocalNews },
  { path: "/ViewApprovedLocalNews", component: ViewApprovedLocalNews },

  //Advertising
  { path: "/ViewuserAds", component: ViewuserAds },
  { path: "/AddAdvertising", component: AddAdvertising },
  { path: "/EditAdvertising", component: EditAdvertising },
  { path: "/ViewAdvertising", component: ViewAdvertising },
  { path: "/AdvertisingList", component: AdvertisingList },
  { path: "/RejectAdvertising", component: RejectAdvertising },
  { path: "/ViewRejectAdvertising", component: ViewRejectAdvertising },
  { path: "/ViewApprovedAdvertising", component: ViewApprovedAdvertising },
  { path: "/ApprovedAdvertisingList", component: ApprovedAdvertisingList },

  //Jobs
  { path: "/Jobs", component: Jobs },
  { path: "/CreateJob", component: CreateJob },
  { path: "/EditJob", component: EditJob },
  { path: "/ViewJob", component: ViewJob },
  { path: "/PendingJobs", component: PendingJobs },
  { path: "/ViewBooking", component: ViewBooking },

  //Classified
  { path: "/Classified", component: Classified },
  { path: "/ServiceCategory", component: ServiceCategory },
  { path: "/CreateClassified", component: CreateClassified },
  { path: "/EditClassified", component: EditClassified },
  { path: "/ViewClassified", component: ViewClassified },
  { path: "/PendindClassified", component: PendindClassified },

  //NewsPaper
  { path: "/NewsPaper", component: NewsPaper },
  { path: "/Vendors", component: Vendors },
  { path: "/Booking", component: Booking },

  //TvNew
  { path: "/TvNew", component: TvNew },
  { path: "/DigitalNews", component: DigitalNews },

  //Banners
  { path: "/Popup", component: Banners },

  //Notifications
  { path: "/Notifications", component: Notifications },
  { path: "/Complaintbox", component: Complaintbox },

  //Settings
  { path: "/Json", component: Json },
  { path: "/Faqs", component: Faqs },
  { path: "/City", component: City },
  { path: "/About", component: About },
  { path: "/State", component: State },
  { path: "/Terms", component: Terms },
  { path: "/District", component: District },
  { path: "/Languages", component: Languages },
  { path: "/PinCode", component: PinCode },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/LanguageSettings", component: LanguageSettings },

  //profile
  { path: "/profile", component: UserProfile },

  //login
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Compareotp", component: Compareotp },
  { path: "/Resetpsw", component: Resetpsw },
]

export { publicRoutes, authProtectedRoutes }
