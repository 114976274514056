import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast, ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import Select from "react-select"
import { URLS } from "Url"
import axios from "axios"

function AddVendors() {
  const [form, setform] = useState({
    serviceId: "",
    serviceName: "",
    phone: "",
    location: "",
    experience: "",
    fromTime: "",
    toTime: "",
    personName: "",
    isWhatsapp: "",
    // company_disc:"",
    stateId:"",
    districtId:"",
    description:""
  })
  const [states1, setstates1] = useState([])
  const [District, setDistrict] = useState([])
  const [files, setFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    if (files.length > 0) {
      Adddealer()
    } else {
      toast("Please upload image")
    }
  }

  const handleDrop = acceptedFiles => {
    const allowedExtensions = ["jpg", "jpeg", "png"]

    // Filter and validate the files
    const validatedFiles = acceptedFiles
      .filter(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase()
        if (!allowedExtensions.includes(fileExtension)) {
          toast(
            `Invalid file format: ${file.name}. Only JPG, JPEG, PNG are allowed.`
          )
          return false
        }
        return true
      })
      .map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatFileSize(file.size),
        })
      )

    // Combine the new files with existing files and enforce the limit
    setFiles(prevFiles => {
      const combinedFiles = [...prevFiles, ...validatedFiles]
      if (combinedFiles.length > 5) {
        toast("Maximum of 5 files can be uploaded.")
        return combinedFiles.slice(0, 5)
      }
      return combinedFiles
    })
  }

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const [selectedMulti, setselectedMulti] = useState([])

  const options = [
    { value: "Telugu", label: "Telugu" },
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
  ]

  function handleMulti(data) {
    setselectedMulti(data)
  }

  const history = useHistory()
  const selectedValuesString = selectedMulti
    .map(option => option.value)
    .join(", ")

  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("serviceId", form.serviceId)
    dataArray.append("phone", form.phone)
    dataArray.append("location", form.location)
    dataArray.append("language", selectedValuesString)
    dataArray.append("experience", form.experience)
    dataArray.append("fromTime", form.fromTime)
    dataArray.append("toTime", form.toTime)
    dataArray.append("personName", form.personName)
    dataArray.append("isWhatsapp", form.isWhatsapp)
    dataArray.append("description", form.description)
    dataArray.append("stateId", form.stateId)
    // dataArray.append("company_disc", form.company_disc)

    if (form.districtId == undefined || form.stateId == "All") {
      dataArray.append("districtId", "All")
    } else {
      dataArray.append("districtId", form.districtId)
    }

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    axios
      .post(URLS.AddClassified, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/Classified",
              sessionStorage.setItem(
                "tost",
                "Classified has been Added successfully"
              )
            )
            clearForm()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      serviceId: "",
      phone: "",
      location: "",
      experience: "",
      fromTime: "",
      toTime: "",
      personName: "",
      isWhatsapp: "",
      stateId:"",
      districtId:"",
      company_disc:"",
      description:""
    })
    setFiles("")
  }

  const [Category, setCategory] = useState([])

  useEffect(() => {
    GetAllServiceCategory()
  }, [])

  const GetAllServiceCategory = () => {
    var token = datas
    axios
      .post(
        URLS.GetServiceCategory,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setCategory(res.data.categorys)
      })
  }
  useEffect(() => {
    getstatess()
  }, [])

  const getstatess = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates1(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.Districts)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Create a Classfied" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <h5 className="text-primary">Create a Classfied </h5>
                <hr></hr>
                <Row className="mt-4">
                  <Col lg="8">
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Service Category Name</Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.serviceId}
                            name="serviceId"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {Category.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.title}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Person Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Person Name"
                            required
                            value={form.personName}
                            name="personName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Phone <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Phone"
                            required
                            value={form.phone}
                            name="phone"
                            onChange={e => {
                              handlechange(e)
                            }}
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            onKeyPress={e => {
                              // Allow only numeric input
                              const charCode = e.which ? e.which : e.keyCode
                              if (charCode < 48 || charCode > 57) {
                                e.preventDefault()
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label> Is WhatsApp Phone</Label>
                          <span className="text-danger">*</span>
                          <select
                            value={form.isWhatsapp}
                            name="isWhatsapp"
                            required
                            onChange={e => {
                              handlechange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </Col>

                      {/* adding the state, distric */}
                      <Col md={6}>
                        <div className="mb-3">
                          <Label> State</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.stateId}
                            name="stateId"
                            onChange={e => {
                              Optionchange(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="All">All</option>
                            {states1.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.title}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      {form.stateId == "All" ? (
                        ""
                      ) : (
                        <Col md={6}>
                          <div className="mb-3">
                            <Label> District</Label>{" "}
                            <span className="text-danger">*</span>
                            <select
                              value={form.districtId}
                              name="districtId"
                              onChange={e => {
                                Optionchange1(e)
                              }}
                              required
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="All">All</option>
                              {District.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.title}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                      )}
{/* =====================================================  */}
                      <Col md={6}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Location <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Location"
                            required
                            value={form.location}
                            name="location"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Languages</Label>
                          <span className="text-danger">*</span>
                          <Select
                            name="Languages"
                            value={selectedMulti}
                            onChange={handleMulti}
                            options={options}
                            required
                            isMulti
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>Year Of Experience </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Experience"
                            required
                            value={form.experience}
                            name="experience"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>From Time </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter From Time"
                            required
                            value={form.fromTime}
                            name="fromTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>To Time </Label>
                          <span className="text-danger">*</span>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter To Time"
                            required
                            value={form.toTime}
                            name="toTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      {/* ADDING THE description */}
                     <Col md={6}>
                        <div className="mb-3 ">
                          <Label for="basicpill-firstname-input1">
                            Description
                          </Label>
                          <textarea
                            type="text"
                            rows="3"
                            className="form-control "
                            id="basicpill-firstname-input1"
                            placeholder="Enter description"
                            required
                            value={form.description}
                            name="description"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>{" "}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="4">
                    {/* <div className="text-center m-2">
                      <h5>Business Log</h5>
                      <div className="w-50 m-auto">
                        <Dropzone onDrop={handleDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzones">
                              <div
                                {...getRootProps()}
                                className="dz-messages needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-2 LOT">
                                  <i
                                    className="display-4 bx bx-plus"
                                    style={{
                                      fontSize: "35px",
                                      paddingTop: "10%",
                                      color: "white",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {files.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <a
                                    href={f.preview}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="40"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </a>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div> */}
                     <div className="text-center m-2">
                        <h5 style={{ fontWeight: "bold" }}>Image </h5>

                        <div className="w-75 m-auto">
                          <Dropzone onDrop={handleDrop}>
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  {...getRootProps()}
                                  className="dz-message needsclick mt-2"
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Upload Image</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>

                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {files.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <a
                                      href={f.preview}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="40"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </a>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                          {/* <div>
                            <span
                              className="text-danger "
                              style={{ fontSize: "10px" }}
                            >
                              (height : 700 px * width : 500 px)
                            </span>
                          </div> */}
                        </div>
                      </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="mb-4 mt-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
