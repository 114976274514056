import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

const Roles = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [ras, setras] = useState([])

  const check = {
    DashView: ras.DashView,

    departmentAdd: ras.departmentAdd,
    departmentEdit: ras.departmentEdit,
    departmentView: ras.departmentView,
    departmentDelete: ras.departmentDelete,

    rolePermitEdit: ras.rolePermitEdit,
    rolePermitView: ras.rolePermitView,

    staffAdd: ras.staffAdd,
    staffEdit: ras.staffEdit,
    staffView: ras.staffView,
    staffDelete: ras.staffDelete,

    customerEdit: ras.customerEdit,
    customerView: ras.customerView,

    localNewsEdit: ras.localNewsEdit,
    localNewsView: ras.localNewsView,
    localNewsAdd: ras.localNewsAdd,
    localNewsDelete: ras.localNewsDelete,

    digitalNewsAdd: ras.digitalNewsAdd,
    digitalNewsEdit: ras.digitalNewsEdit,
    digitalNewsView: ras.digitalNewsView,
    digitalNewsDelete: ras.digitalNewsDelete,

    tvNewsAdd: ras.tvNewsAdd,
    tvNewsEdit: ras.tvNewsEdit,
    tvNewsView: ras.tvNewsView,
    tvNewsDelete: ras.tvNewsDelete,

    advertiseEdit: ras.advertiseEdit,
    advertiseView: ras.advertiseView,
    advertiseAdd: ras.advertiseAdd,
    advertiseDelete: ras.advertiseDelete,

    popupEdit: ras.popupEdit,
    popupView: ras.popupView,

    notificationAdd: ras.notificationAdd,
    notificationView: ras.notificationView,
    notificationDelete: ras.notificationDelete,

    complaintBoxEdit: ras.complaintBoxEdit,
    complaintBoxView: ras.complaintBoxView,

    settingsAdd: ras.settingsAdd,
    settingsEdit: ras.settingsEdit,
    settingsView: ras.settingsView,
    settingsDelete: ras.settingsDelete,
  }

  const [form, setform] = useState([])

  const [ras1, setras1] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    Addrole()
  }

  const Addrole = () => {
    const token = datas
    const id = ras1
    axios
      .put(
        URLS.UpdateRole + id,
        {
          rolesPermissions: check,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  function handleChange(e) {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    var token = datas

    const dataArray = new FormData()
    dataArray.append("_id", e.target.value)

    axios
      .post(URLS.GetOneDepartment, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          setras(res.data.depResult.rolesPermissions)
          setras1(res.data.depResult._id)
        },
        error => {
          if (error.response && error.response.status === 400) {
            // toast(error.response.data.message)
            setras1([])
            setras([])
          }
        }
      )
  }

  useEffect(() => {
    getAllRoles()
  }, [])

  const [rol, setrol] = useState([])

  const getAllRoles = () => {
    var token = datas
    axios
      .post(
        URLS.GetDepartment,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setrol(res.data.departments)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            // setrol([""])
          }
        }
      )
  }

  const handleChange1s = e => {
    const myUser = { ...ras }
    myUser[e.target.name] = e.target.checked
    setras(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  const roles = data.rolesAndPermission
  const roles1 = data.user.departementName

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="Add Roles" />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white mt-2">
                  <CardTitle>Role & Permissions</CardTitle>
                </CardHeader>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <Label> Roles</Label>{" "}
                        <span className="text-danger">*</span>
                        <select
                          value={form.role}
                          name="role"
                          onChange={e => {
                            handleChange(e)
                          }}
                          className="form-select"
                        >
                          <option value="">Select</option>
                          {rol.map((data, key) => {
                            return (
                              <option key={key} value={data._id}>
                                {data.departmentName}
                              </option>
                            )
                          })}
                        </select>
                      </Col>
                    </Row>

                    <Row className=" mt-3">
                      <Col md={2}>
                        {" "}
                        <p className="">Dashboard: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DashView"
                            defaultChecked={ras.DashView}
                            value={ras.DashView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Roles : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolePermitView"
                            defaultChecked={ras.rolePermitView}
                            value={ras.rolePermitView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="roleView">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolePermitEdit"
                            defaultChecked={ras.rolePermitEdit}
                            value={ras.rolePermitEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="roleDelete">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Popup : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="popupView"
                            defaultChecked={ras.popupView}
                            value={ras.popupView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="popupEdit"
                            defaultChecked={ras.popupEdit}
                            value={ras.popupEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Complaint Box : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="complaintBoxView"
                            defaultChecked={ras.complaintBoxView}
                            value={ras.complaintBoxView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="complaintBoxEdit"
                            defaultChecked={ras.complaintBoxEdit}
                            value={ras.complaintBoxEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Users : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="customerView"
                            defaultChecked={ras.customerView}
                            value={ras.customerView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            className="form-check-label"
                            for="customerView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="customerEdit"
                            defaultChecked={ras.customerEdit}
                            value={ras.customerEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            className="form-check-label"
                            for="customerView"
                          >
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Notifications : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationAdd"
                            defaultChecked={ras.notificationAdd}
                            value={ras.notificationAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationView"
                            defaultChecked={ras.notificationView}
                            value={ras.notificationView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationDelete"
                            defaultChecked={ras.notificationDelete}
                            value={ras.notificationDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Departments : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departmentAdd"
                            defaultChecked={ras.departmentAdd}
                            value={ras.departmentAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departmentView"
                            defaultChecked={ras.departmentView}
                            value={ras.departmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departmentEdit"
                            defaultChecked={ras.departmentEdit}
                            value={ras.departmentEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departmentDelete"
                            defaultChecked={ras.departmentDelete}
                            value={ras.departmentDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Staff : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffAdd"
                            defaultChecked={ras.staffAdd}
                            value={ras.staffAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffView"
                            defaultChecked={ras.staffView}
                            value={ras.staffView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffEdit"
                            defaultChecked={ras.staffEdit}
                            value={ras.staffEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffDelete"
                            defaultChecked={ras.staffDelete}
                            value={ras.staffDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="subcribes">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Local News : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="localNewsAdd"
                            defaultChecked={ras.localNewsAdd}
                            value={ras.localNewsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="addbanner">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="localNewsView"
                            defaultChecked={ras.localNewsView}
                            value={ras.localNewsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="banner"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="localNewsEdit"
                            defaultChecked={ras.localNewsEdit}
                            value={ras.localNewsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="editbanner">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="localNewsDelete"
                            defaultChecked={ras.localNewsDelete}
                            value={ras.localNewsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="delbanner">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Advertise : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="advertiseAdd"
                            defaultChecked={ras.advertiseAdd}
                            value={ras.advertiseAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="advertiseView"
                            defaultChecked={ras.advertiseView}
                            value={ras.advertiseView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="advertiseEdit"
                            defaultChecked={ras.advertiseEdit}
                            value={ras.advertiseEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="advertiseDelete"
                            defaultChecked={ras.advertiseDelete}
                            value={ras.advertiseDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Digital News: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="digitalNewsAdd"
                            defaultChecked={ras.digitalNewsAdd}
                            value={ras.digitalNewsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="digitalNewsView"
                            defaultChecked={ras.digitalNewsView}
                            value={ras.digitalNewsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="digitalNewsEdit"
                            defaultChecked={ras.digitalNewsEdit}
                            value={ras.digitalNewsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="digitalNewsDelete"
                            defaultChecked={ras.digitalNewsDelete}
                            value={ras.digitalNewsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Tv News : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="tvNewsAdd"
                            defaultChecked={ras.tvNewsAdd}
                            value={ras.tvNewsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="tvNewsView"
                            defaultChecked={ras.tvNewsView}
                            value={ras.tvNewsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="tvNewsEdit"
                            defaultChecked={ras.tvNewsEdit}
                            value={ras.tvNewsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="tvNewsDelete"
                            defaultChecked={ras.tvNewsDelete}
                            value={ras.tvNewsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>

                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Settings : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsAdd"
                            defaultChecked={ras.settingsAdd}
                            value={ras.settingsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsView"
                            defaultChecked={ras.settingsView}
                            value={ras.settingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsEdit"
                            defaultChecked={ras.settingsEdit}
                            value={ras.settingsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsDelete"
                            defaultChecked={ras.settingsDelete}
                            value={ras.settingsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    {roles.rolePermitEdit === true ||
                    roles1 === "superadmin" ? (
                      <>
                        <div className="mt-3" style={{ float: "right" }}>
                          {/* <button
                            type="submit"
                            style={{ width: "120px" }}
                            className="btn btn-primary m-1"
                          >
                            Submit{" "}
                            <i
                              className="fa fa-check-circle-o"
                              aria-hidden="true"
                            ></i>
                          </button> */}
                          <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Roles
