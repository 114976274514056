import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"

function State() {
  const [forms, setforms] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetOneOrder()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Jobid = sessionStorage.getItem("JobId")

  const GetOneOrder = () => {
    const data = {
      jobId: Jobid,
    }
    var token = datas
    axios
      .post(URLS.GetoneJob, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.data)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Paper Boys" breadcrumbItem="View Job " />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row className="mt-3">
                    <Col lg={4}>
                      <h5 className="mb-4 text-primary">Job Information :-</h5>
                      <ul className="verti-timeline list-unstyled">
                        <img
                          src={URLS.Base + forms.companyLogo}
                          height="80px"
                          width="100px"
                        ></img>
                        <li className="event-list mt-5">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Company Name
                                </h6>
                                <p className="text-muted">
                                  {forms.companyName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Company Phone
                                </h6>
                                <p className="text-muted">{forms.companyNo}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Company Email
                                </h6>
                                <p className="text-muted">
                                  {forms.companyEmail}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Company Location
                                </h6>
                                <p className="text-muted">
                                  {forms.companyLocation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">State</h6>
                                <p className="text-muted">{forms.stateName}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">District</h6>
                                <p className="text-muted">{forms.districtName}</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Number Of Openings
                                </h6>
                                <p className="text-muted">
                                  {forms.NumberOfOpenings}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Qualification</h6>
                                <p className="text-muted">
                                  {forms.qualification}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={4}>
                      <ul className="verti-timeline list-unstyled ">
                        <li className="event-list mt-5">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">Job Title</h6>
                                <p className="text-muted">{forms.jobTitle}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Job Category
                                </h6>
                                <p className="text-muted">
                                  {forms.jobCategory}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list  mt-2">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Job Location</h6>
                                <p className="text-muted">
                                  {forms.jobLocation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Language</h6>
                                <p className="text-muted">{forms.language}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Job Type</h6>
                                <p className="text-muted">{forms.jobType}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Min Salary</h6>
                                <p className="text-muted">{forms.minSalary}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list  ">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Max Salary</h6>
                                <p className="text-muted">{forms.maxSalary}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Interview Type</h6>
                                <p className="text-muted">
                                  {forms.interviewType}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Experience</h6>
                                <p className="text-muted">{forms.experience}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={4}>
                      <ul className="verti-timeline list-unstyled">
                       
                        <li className="event-list mt-5">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">From Time</h6>
                                <p className="text-muted">{forms.fromTime}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">To Time</h6>
                                <p className="text-muted">{forms.toTime}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Shift</h6>
                                <p className="text-muted">{forms.shift}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Job Hours</h6>
                                <p className="text-muted">{forms.jobHours}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                       
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14">Description</h6>
                                <p className="text-muted">{forms.company_disc}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
